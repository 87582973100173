// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import { Box, TextField, Button, Grid, Typography } from "@mui/material";
// import marketingLogo from "../../main/img/marketing_logo.webp";
// import { useNavigate } from "react-router-dom";

// export const LoginForm = () => {
//   const [UserName, setUserName] = useState("");
//   const [password, setPassword] = useState("");
//   const [responseMessageError, setResponseMessageError] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const navigate = useNavigate();

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));

//   const handleLogoClick = () => {
//     navigate("/new");
//   };

//   const handleLogin = async () => {
//     try {
//       if (UserName === "admin@marketing.inc" && password === "Admin@123")
//         navigate("/dashboard");
//       else setResponseMessageError(" Incorrect Username and Password");
//     } catch {
//       setResponseMessageError("Something went wrong. Please contact admin!");
//     }
//   };

//   return (
//     <Grid
//       container
//       xs={12}
//       md={12}
//       lg={12}
//       sx={{ flexGrow: 1, padding: 6, justifyContent: "center" }}
//     >
//       <Grid
//         container
//         direction="column"
//         alignItems="center"
//         justifyContent="center"
//         spacing={2}
//         component="form"
//         noValidate
//         autoComplete="off"
//         sx={{
//           // background: "linear-gradient(to right, #0A1828, #178582, #BFA181)",
//           background: "#e6e6e6",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           p: 4,
//           borderRadius: 2,
//           boxShadow: 3,
//           maxWidth: 400,
//           margin: "auto",
//           minHeight: "60vh",
//           mt: 3,
//         }}
//       >
//         <Grid
//           item
//           component="img"
//           src={marketingLogo}
//           alt="Marketing Inc Logo"
//           sx={{
//             width: isMobile ? "60%" : "60%",
//             height: "auto",
//             marginBottom: 2,
//             cursor: "pointer",
//           }}
//           onClick={handleLogoClick}
//         />

//         <Grid container spacing={1}>
//           <Grid item xs={12} md={12} lg={12}>
//             {responseMessage && (
//               <Typography color="green" align="center" sx={{ mt: 2 }}>
//                 {responseMessage}
//               </Typography>
//             )}
//             {responseMessageError && (
//               <Typography color="red" align="center" sx={{ mt: 2 }}>
//                 {responseMessageError}
//               </Typography>
//             )}

//             <Typography align="left" color="black" mt={2}>
//               Enter Username
//             </Typography>
//             <TextField
//               label="Username"
//               type="username"
//               variant="outlined"
//               margin="normal"
//               value={UserName}
//               onChange={(e) => setUserName(e.target.value)}
//               required
//               fullWidth
//               InputLabelProps={{
//                 style: { color: "black" },
//               }}
//               sx={{
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "black",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "black",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "black",
//                   },
//                   "& input": {
//                     color: "black",
//                   },
//                 },
//               }}
//             />
//           </Grid>
//           <Grid item xs={12} md={12} lg={12}>
//             <Typography align="left" color="black">
//               Enter Correct Password
//             </Typography>
//             <TextField
//               label="Password"
//               type="password"
//               variant="outlined"
//               margin="normal"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//               fullWidth
//               InputLabelProps={{
//                 style: { color: "black" },
//               }}
//               sx={{
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "black",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "black",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "black",
//                   },
//                   "& input": {
//                     color: "black",
//                   },
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
//           <Grid item>
//             <Button variant="contained" color="primary" onClick={handleLogin}>
//               Login
//             </Button>
//           </Grid>
//           <Grid item>
//             <Button
//               variant="outlined"
//               onClick={() => {
//                 setUserName("");
//                 setPassword("");
//               }}
//               sx={{
//                 color: "white",
//                 borderColor: "red",
//                 backgroundColor: "red",
//               }}
//             >
//               Cancel
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };

// export default LoginForm;


import React, { useState } from "react";
import axios from "axios";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, TextField, Button, Grid, Typography, InputAdornment, IconButton } from "@mui/material";
import marketingLogo from "../../main/img/marketing_logo.webp";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const LoginForm = () => {
  const [UserName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [responseMessageError, setResponseMessageError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogoClick = () => {
    navigate("/new");
  };

  const handleLogin = async () => {
    try {
      if (UserName === "admin@marketing.inc" && password === "Admin@123") {
        navigate("/dashboard");
      } else {
        setResponseMessageError("Incorrect Username and Password");
      }
    } catch {
      setResponseMessageError("Something went wrong. Please contact admin!");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      sx={{ flexGrow: 1, padding: 6, justifyContent: "center" }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          background: "#e6e6e6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 400,
          margin: "auto",
          minHeight: "60vh",
          mt: 3,
        }}
      >
        <Grid
          item
          component="img"
          src={marketingLogo}
          alt="Marketing Inc Logo"
          sx={{
            width: isMobile ? "60%" : "60%",
            height: "auto",
            marginBottom: 2,
            cursor: "pointer",
          }}
          onClick={handleLogoClick}
        />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            {responseMessage && (
              <Typography color="green" align="center" sx={{ mt: 2 }}>
                {responseMessage}
              </Typography>
            )}
            {responseMessageError && (
              <Typography color="red" align="center" sx={{ mt: 2 }}>
                {responseMessageError}
              </Typography>
            )}

            <Typography align="left" color="black" mt={2}>
              Enter Username
            </Typography>
            <TextField
              label="Username"
              type="text"
              variant="outlined"
              margin="normal"
              value={UserName}
              onChange={(e) => setUserName(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                  "& input": {
                    color: "black",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" color="black">
              Enter Correct Password
            </Typography>
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => e.preventDefault()}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                  "&:hover fieldset": {
                    borderColor: "black",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                  "& input": {
                    color: "black",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Login
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setUserName("");
                setPassword("");
              }}
              sx={{
                color: "white",
                borderColor: "red",
                backgroundColor: "red",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
