// AuthorPage.js
// import React from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Divider,
//   Button,
//   Pagination,
//   useMediaQuery,
// } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
// import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
// import ContentMarketingImg from "../main/img/content-marketing.jpg";
// import EmailMarketingImg from "../main/img/email-marketing.jpg";
// import NavBar from "../global/header";
// import Footer from "../global/footer";
// import { useTheme } from "@mui/material/styles";

// export const AuthorPage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const articles = [
//     {
//       id: 1,
//       title:
//         "Uncovering the Secret Behind Startups: Learn About Types of Startups",
//       date: "August 31, 2024",
//       category: "Listicle",
//       imageUrl: SocialMediaMarketingImg,
//       excerpt: "XYZ",
//     },
//     {
//       id: 2,
//       title: "The Best SaaS Marketplaces and App Exchanges",
//       date: "July 24, 2024",
//       category: "Companies",
//       imageUrl: MobileMarketingImg,
//       excerpt: "Explore top SaaS marketplaces like Salesforce AppExchange...",
//     },
//     {
//       id: 3,
//       title: "The Best Options For Landing Page Builder Software Tools",
//       date: "August 26, 2024",
//       category: "Listicle",
//       imageUrl: EmailMarketingImg,
//       excerpt: "Landing page builder software can help you create your own...",
//     },
//     {
//       id: 4,
//       title: "Unveiling the Powerful Duo: AI and IoT in SaaS Applications",
//       date: "August 28, 2024",
//       category: "Operations",
//       imageUrl: ContentMarketingImg,
//       excerpt:
//         "As we embark on this transformative journey, we must be aware...",
//     },
//   ];

//   return (
//     <Grid
//       xs={12}
//       md={8}
//       lg={8}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Container maxWidth="lg" sx={{ marginTop: 19 }}>
//         <Grid container spacing={4}>
//           {/* Blog Posts Section */}
//           <Grid item xs={12} md={8}>
//             <Typography
//               variant="h4"
//               sx={{ fontWeight: "bold", marginBottom: 2 }}
//             >
//               Post By Manish Balakrishnan
//             </Typography>
//             <Grid container spacing={3}>
//               {articles.map((article) => (
//                 <Grid item xs={12} md={6} key={article.id}>
//                   <Card sx={{ height: "100%" }}>
//                     <img
//                       src={article.imageUrl}
//                       alt={article.title}
//                       style={{ width: "100%", height: 150, objectFit: "cover" }}
//                     />
//                     <CardContent>
//                       <Typography variant="subtitle2" color="textSecondary">
//                         {article.category.toUpperCase()} | {article.date}
//                       </Typography>
//                       <Typography variant="h6" sx={{ marginTop: 1 }}>
//                         {article.title}
//                       </Typography>
//                       <Typography variant="body2" color="textSecondary">
//                         {article.excerpt}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//             <Box
//               sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
//             >
//               <Pagination count={10} color="primary" />
//             </Box>
//           </Grid>

//           {/* Author Profile Section */}
//           <Grid item xs={12} md={4}>
//             <Card sx={{ padding: 2, textAlign: "center" }}>
//               <Avatar
//                 src="https://via.placeholder.com/150"
//                 alt="Manish Balakrishnan"
//                 sx={{ width: 150, height: 150, margin: "0 auto" }}
//               />
//               <Typography variant="h5" sx={{ marginTop: 2 }}>
//                 Manish Balakrishnan
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="textSecondary"
//                 sx={{ marginTop: 1 }}
//               >
//                 Manish is a veteran technology entrepreneur who has been leading
//                 technology and marketing leadership teams across domains. He
//                 runs his own startup consulting company as well as a web app
//                 development company.
//               </Typography>
//               <Divider sx={{ marginY: 2 }} />
//               <Box>
//                 <Button
//                   startIcon={<LinkedInIcon />}
//                   href="https://www.linkedin.com"
//                   target="_blank"
//                   sx={{ marginRight: 1 }}
//                 >
//                   LinkedIn
//                 </Button>
//                 <Button
//                   startIcon={<TwitterIcon />}
//                   href="https://www.twitter.com"
//                   target="_blank"
//                 >
//                   Twitter
//                 </Button>
//               </Box>
//             </Card>
//           </Grid>
//         </Grid>
//       </Container>
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AuthorPage;

// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Divider,
//   Button,
//   Pagination,
//   useMediaQuery,
// } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import NavBar from "../global/header";
// import Footer from "../global/footer";
// import { useTheme } from "@mui/material/styles";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";

// const AuthorPage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticles] = useState([]);
//   const { authorName } = useParams();

//   // Fetch articles by author
//   useEffect(() => {
//     const fetchArticlesByAuthor = async () => {
//       console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^6");

//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
//         );

//         setArticles(response.data);
//       } catch (error) {
//         console.error("Error fetching articles by author:", error);
//       }
//     };

//     fetchArticlesByAuthor();
//   }, [authorName]);

//   return (
//     <Grid
//       xs={12}
//       md={8}
//       lg={8}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Container maxWidth="lg" sx={{ marginTop: 19 }}>
//         <Grid container spacing={4}>
//           {/* Blog Posts Section */}
//           <Grid item xs={12} md={8}>
//             <Typography
//               variant="h4"
//               sx={{ fontWeight: "bold", marginBottom: 2 }}
//             >
//               Posts by {authorName}
//             </Typography>
//             <Grid container spacing={3}>
//               {articles.map((article) => (
//                 <Grid item xs={12} md={6} key={article._id}>
//                   <Link
//                     to={`/seo/${article.slug}`}
//                     style={{ textDecoration: "none" }}
//                   >
//                     <Card sx={{ height: "100%" }}>
//                       <img
//                         src={article.imagelink}
//                         alt={article.title}
//                         style={{
//                           width: "100%",
//                           height: 150,
//                           objectFit: "cover",
//                         }}
//                       />
//                       <CardContent>
//                         <Typography variant="subtitle2" color="textSecondary">
//                           {article.category.toUpperCase()} | {article.date}
//                         </Typography>
//                         <Typography
//                           variant="h6"
//                           sx={{ marginTop: 1, cursor: "pointer" }}
//                         >
//                           {article.headertext}
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                           {article.shortdescription}
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Link>
//                 </Grid>
//               ))}
//             </Grid>
//             <Box
//               sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
//             >
//               <Pagination count={10} color="primary" />
//             </Box>
//           </Grid>

//           {/* Author Profile Section */}
//           <Grid item xs={12} md={4}>
//             <Card sx={{ padding: 2, textAlign: "center" }}>
//               <Avatar
//                 src="https://via.placeholder.com/150"
//                 alt={authorName}
//                 sx={{ width: 150, height: 150, margin: "0 auto" }}
//               />
//               <Typography variant="h5" sx={{ marginTop: 2 }}>
//                 {authorName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="textSecondary"
//                 sx={{ marginTop: 1 }}
//               > 
               
//               </Typography>
//               <Divider sx={{ marginY: 2 }} />
//               <Box>
//                 <Button
//                   startIcon={<LinkedInIcon />}
//                   href="https://www.linkedin.com"
//                   target="_blank"
//                   sx={{ marginRight: 1 }}
//                 >
//                   LinkedIn
//                 </Button>
//                 <Button
//                   startIcon={<TwitterIcon />}
//                   href="https://www.twitter.com"
//                   target="_blank"
//                 >
//                   Twitter
//                 </Button>
//               </Box>
//             </Card>
//           </Grid>
//         </Grid>
//       </Container>
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AuthorPage;


// AuthorPage.js
// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Divider,
//   Button,
//   Pagination,
//   useMediaQuery,
// } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import NavBar from "../global/header";
// import Footer from "../global/footer";
// import { useTheme } from "@mui/material/styles";
// import axios from "axios";
// import { useParams, Link } from "react-router-dom";
// import imageCompression from "browser-image-compression"; // Import the image compression library

// const AuthorPage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticles] = useState([]);
//   const [author, setAuthor] = useState(null); // State to store author details
//   const { authorName } = useParams();

//   // Fetch articles by author
//   useEffect(() => {
//     const fetchArticlesByAuthor = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
//         );
//         setArticles(response.data);
//       } catch (error) {
//         console.error("Error fetching articles by author:", error);
//       }
//     };

//     const fetchAuthorDetails = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/authors/getAllAuthors`
//         );
//         const fetchedAuthors = response.data.authors;
//         const currentAuthor = fetchedAuthors.find(
//           (auth) => auth.authorName === authorName
//         );
//         setAuthor(currentAuthor);
//       } catch (error) {
//         console.error("Error fetching author details:", error);
//       }
//     };

//     fetchArticlesByAuthor();
//     fetchAuthorDetails();
//   }, [authorName]);

//   // Handle Image Upload
//   const handleImageUpload = async (event) => {
//     const imageFile = event.target.files[0];

//     // Compression options
//     const options = {
//       maxSizeMB: 1, // Maximum size in MB
//       maxWidthOrHeight: 1920, // Max width or height
//       useWebWorker: true,
//     };

//     try {
//       const compressedFile = await imageCompression(imageFile, options);
//       const formData = new FormData();
//       formData.append("file", compressedFile);

//       // Post the compressed file to the server
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/article-images`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       console.log("Image uploaded successfully:", response.data);
//     } catch (error) {
//       console.error("Error uploading image:", error);
//     }
//   };

//   return (
//     <Grid
//       xs={12}
//       md={8}
//       lg={8}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Container maxWidth="lg" sx={{ marginTop: 19 }}>
//         <Grid container spacing={4}>
//           {/* Blog Posts Section */}
//           <Grid item xs={12} md={8}>
//             <Typography
//               variant="h4"
//               sx={{ fontWeight: "bold", marginBottom: 2 }}
//             >
//               Posts by {authorName}
//             </Typography>
//             <Grid container spacing={3}>
//               {articles.map((article) => (
//                 <Grid item xs={12} md={6} key={article._id}>
//                   <Link
//                     to={`/seo/${article.slug}`}
//                     style={{ textDecoration: "none" }}
//                   >
//                     <Card sx={{ height: "100%" }}>
//                       <img
//                         src={article.imagelink}
//                         alt={article.title}
//                         style={{
//                           width: "100%",
//                           height: 150,
//                           objectFit: "cover",
//                         }}
//                       />
//                       <CardContent>
//                         <Typography variant="subtitle2" color="textSecondary">
//                           {article.category.toUpperCase()} | {article.date}
//                         </Typography>
//                         <Typography
//                           variant="h6"
//                           sx={{ marginTop: 1, cursor: "pointer" }}
//                         >
//                           {article.headertext}
//                         </Typography>
//                         <Typography variant="body2" color="textSecondary">
//                           {article.shortdescription}
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Link>
//                 </Grid>
//               ))}
//             </Grid>
//             <Box
//               sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
//             >
//               <Pagination count={10} color="primary" />
//             </Box>
//           </Grid>

          
//           <Grid item xs={12} md={4}>
//             <Card sx={{ padding: 2, textAlign: "center" }}>
//               <Avatar
//                 src={author?.file || "https://via.placeholder.com/150"} 
//                 alt={authorName}
//                 sx={{ width: 150, height: 150, margin: "0 auto" }}
//               />
//               <Typography variant="h5" sx={{ marginTop: 2 }}>
//                 {authorName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="textSecondary"
//                 sx={{ marginTop: 1 }}
//               >
//                 {author?.description || "No description available."}
//               </Typography>
//               <Divider sx={{ marginY: 2 }} />
//               <Box>
//                 <Button
//                   startIcon={<LinkedInIcon />}
//                   href={author?.linkedIn || "https://www.linkedin.com"}
//                   target="_blank"
//                   sx={{ marginRight: 1 }}
//                 >
//                   LinkedIn
//                 </Button>
//                 <Button
//                   startIcon={<TwitterIcon />}
//                   href={author?.twitter || "https://www.twitter.com"}
//                   target="_blank"
//                 >
//                   Twitter
//                 </Button>
//               </Box>
//               {/* Input for uploading an image */}
//               <input
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageUpload}
//                 style={{ marginTop: 20 }}
//               />
//             </Card>
//           </Grid>
//         </Grid>
//       </Container>
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AuthorPage;


import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Button,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import NavBar from "../global/header";
import Footer from "../global/footer";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import imageCompression from "browser-image-compression";

const AuthorPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [articles, setArticles] = useState([]);
  const [author, setAuthor] = useState(null);
  const { authorName } = useParams();

  // Fetch articles by author
  useEffect(() => {
    const fetchArticlesByAuthor = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
        );
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles by author:", error);
      }
    };

    const fetchAuthorDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/authors/getAllAuthors`
        );
        const fetchedAuthors = response.data.authors;
        const currentAuthor = fetchedAuthors.find(
          (auth) => auth.authorName === authorName
        );
        setAuthor(currentAuthor);
      } catch (error) {
        console.error("Error fetching author details:", error);
      }
    };

    fetchArticlesByAuthor();
    fetchAuthorDetails();
  }, [authorName]);

  // Handle Image Upload
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    // Compression options
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const formData = new FormData();
      formData.append("file", compressedFile);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/article-images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Image uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />

      <Container maxWidth="lg" sx={{ marginTop: 19 }}>
        <Grid container spacing={4}>
          {/* Blog Posts Section */}
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Posts by {authorName}
            </Typography>
            <Grid container spacing={3}>
              {articles.map((article) => (
                <Grid item xs={12} md={6} key={article._id}>
                  <Link
                    to={`/seo/${article.slug}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card sx={{ height: "100%" }}>
                      <img
                        src={article.imagelink}
                        alt={article.title}
                        style={{
                          width: "100%",
                          height: 150,
                          objectFit: "cover",
                        }}
                      />
                      <CardContent>
                        <Typography variant="subtitle2" color="textSecondary">
                          {article.category.toUpperCase()} | {article.date}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ marginTop: 1, cursor: "pointer" }}
                        >
                          {article.headertext}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {article.shortdescription}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
            >
              <Pagination count={10} color="primary" />
            </Box>
          </Grid>

          {/* Author Details Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: 2, textAlign: "center" }}>
              <Avatar
                src={author?.file || "https://via.placeholder.com/150"} // Ensure this line accesses the correct URL
                alt={authorName}
                sx={{ width: 150, height: 150, margin: "0 auto" }}
              />
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                {authorName}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: 1 }}
              >
                {author?.description || "No description available."}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
              <Box>
                <Button
                  startIcon={<LinkedInIcon />}
                  href={author?.linkedIn || "https://www.linkedin.com"}
                  target="_blank"
                  sx={{ marginRight: 1 }}
                >
                  LinkedIn
                </Button>
                <Button
                  startIcon={<TwitterIcon />}
                  href={author?.twitter || "https://www.twitter.com"}
                  target="_blank"
                >
                  Twitter
                </Button>
              </Box>
              {/* Input for uploading an image */}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginTop: 20 }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AuthorPage;
