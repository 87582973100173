import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import { useContext } from "react";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Navigate, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";

export const HeaderTopbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Grid item xs={1} sm={6} md={4}>
          <Tooltip title="Logout">
            {/* <IconButton onClick={handleLogout}>
            <PersonOutlineOutlinedIcon sx={{color:'white'}}/>
          </IconButton> */}
            <Button
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              sx={{ color: "blue", textTransform: "none" }}
            >
              Logout
            </Button>
          </Tooltip>
        </Grid>
      </Box>
    </Box>
  );
};

export default HeaderTopbar;
