import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NavBar from "./header";
import background from "../main/img/mark.jpg";
import {
  Box,
  Button,
  CardMedia,
  CardActions,
  Paper,
  Link,
} from "@mui/material";
import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
import ContentMarketingImg from "../main/img/content-marketing.jpg";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dropImg from "../main/img/drop.jpg";
// import EmailMarketingImg from '../main/img/email-marketing.jpg';
import seoImg from "../main/img/seo.jpg"; // Add this image
import DigitalImg from "../main/img/Digital.png";
// import InstallationCard from './InstallationCard';
import { styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import { ArrowForward } from "@mui/icons-material";
import { borderRadius, color, textAlign } from "@mui/system";
import img1 from "../main/img/img1.png";
import digi from "../main/img/digi.jpg";
import email from "../main/img/email.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import robo from "../main/img/robo.gif";
import abc from "../main/img/abc.webp";
import dolar from "../main/img/dolar.webp";
import tightropeImage from "../main/img/tightrope.png";
import unnamed9Image from "../main/img/unnamed9.png";
import bakingcookiesImage from "../main/img/bakingcookies.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./footer";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { useNavigate } from "react-router-dom";

const CustomCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: "#EBF5FB ", // Set background color to transparent
  boxShadow: theme.shadows[3], // Adding a subtle shadow
  transition: "transform 0.3s ease-in-out", // Smooth transform on hover
  "&:hover": {
    transform: "scale(1.05)", // Scale up on hover for attraction
  },
}));

const categories = [
  "Technology",
  "Leadership",
  "Talent",
  "Culture",
  "Explainers",
];

const articles = [
  {
    category: "DE&I",
    title:
      "‘A deeply unequal act:’ HR execs alarmed by SHRM’s decision to drop the ‘E’ from ‘DE&I’",
  },
  {
    category: "Culture",
    title: "What Biden's age discussion means for workplace ageism",
  },
  {
    category: "Technology",
    title:
      "HR leaders with AI skills in high demand and getting major pay raises",
  },
  {
    category: "Technology",
    title: "WTF is Shadow AI?",
  },
  {
    category: "Leadership",
    title: "How Mastercard is leveraging AI in talent acquisition",
  },
];

const theme = createTheme({
  typography: {
    fontFamily: "Lora, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#1976d2", // Adjust to match the blue color in the image
    },
  },
});

function Linkage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid sx={{ display: "flex", alignItems: "center", mb: 6 }}>
        <Grid item xs={12} md={4} lg={12}>
          {!isMobile && (
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                flex: 1,

                fontWeight: "bold",
                mt: { xs: 2, md: 0 },
                fontStyle: "italic",
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={4} lg={6}>
          <Typography
            sx={{
              textAlign: "center",
              color: "black",
              fontSize: { xs: "0.75rem", sm: "0.95rem" },
              fontFamily: "Graphik",
            }}
          >
            {/* <Link href="xyzImg" sx={{ color: "red" }}>
              Subscribe to Marketing Inc+
            </Link>{" "} */}
            <bold>
            Marketing Inc
            </bold>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} lg={12}>
          {!isMobile && (
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                flex: 1,

                fontWeight: "bold",
                mt: { xs: 2, md: 0 },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ReadCard() {
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(1, 7);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  console.log("====================================", article);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 4,
          mt: 1,
          textAlign: "left",
          ml: 0,
        }}
      >
        {article.map((article, index) => (
          <Grid
            key={index}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column", lg: "column" },
              padding: 0,
              height: { xs: "91%", sm: "42%", md: "10%", lg: "55%" },
              width: { xs: "91%", sm: "42%", md: "28%", lg: "30%" },
              cursor: "pointer",
              mb: 2,
              gap: 4,

              alignItems: { xs: "center", md: "flex-start", lg: "flex-start" },
            }}
            onClick={() => handleClick(article.slug)}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "row", md: "column", lg: "column" },
                width: "100%",
              }}
            >
              <CardMedia
                component="img"
                image={article.imagelink}
                alt={article.headertext}
                sx={{
                  height: { xs: 90, md: 70, lg: 150 },
                  width: { xs: "40%", md: "95%", lg: "105%" },
                  objectFit: "cover",
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h6">
                  {article.headertext}
                </Typography>
{/* 
                <Typography variant="body2" color="text.secondary">
                  {article.shortdescription}
                </Typography> */}

                <Typography
                  variant="body2"
                  sx={{
                    display: "flex", // Flex display to keep icon and text on the same line
                    alignItems: "center", // Center the icon and text vertically
                    textAlign: "left",
                    mt: 5,
                    alignSelf: "flex-start", // Align to the start to position it on the left
                  }}
                >
                  <RemoveRedEyeIcon
                 sx={{ 
                  marginLeft: 0.5, 
                  marginRight: 0.5,
                  color: "grey.900",        // Adjust the color to make the icon lighter
                  opacity: 0.7,             // Reduce opacity to soften the icon further
                }} 
                  />
                  {article.clicks}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function BlogPosts() {
  const blogs = [
    {
      category: "AGENCY CULTURE",
      title:
        "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
      date: "July 16, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "July 15, 2024",
    },
    {
      category: "SPONSORED",
      title:
        "Q&A: How advertisers are optimizing CTV ad spend and measuring success",
      date: "July 10, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "July 15, 2024",
    },
    {
      category: "THE CREATOR ECONOMY",
      title: "Why longer videos are becoming more commonplace on YouTube",
      date: "July 14, 2024",
    },
    {
      category: "SPONSORED",
      title:
        "From Killabears to the Stanley Cup Playoffs, how AR drove deep audience engagement",
      date: "July 12, 2024",
    },
    {
      category: "AGENCY CULTURE",
      title:
        "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
      date: "July 16, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "July 15, 2024",
    },
  ];

  return (
    <Grid container spacing={2} sx={{ textAlign: "left" }}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          variant="h6"
          component="div"
          textAlign="left"
          sx={{
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "800",
            marginBottom: 3,
          }}
        >
          LATEST NEWS
        </Typography>
      </Grid>
      {blogs.map((blog, index) => (
        <Grid item xs={12} key={index}>
          <Typography
            variant="overline"
            display="block"
            gutterBottom
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "0.55rem",
              marginBottom: 1,
            }}
          >
            {blog.category}
          </Typography>
          <Typography
            variant="h8"
            component="div"
            gutterBottom
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            {blog.title}
          </Typography>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {blog.date}
          </Typography>
          {index < blogs.length - 1 && (
            <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ImageSection1() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                    sx={{ 
                      marginLeft: 0.5, 
                      marginRight: 0.5,
                      color: "grey.900",        // Adjust the color to make the icon lighter
                      opacity: 0.7,             // Reduce opacity to soften the icon further
                    }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "00px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

// function ImageSection2() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent={"center"}
//       alignItems="center"
//       paddingTop={2}
//     >
//       <Grid item xs={12} md={6}>
//         <Grid container alignItems="center" spacing={1}>
//           <Grid item xs={12} md={4}>
//             <Typography
//               variant="subtitle1"
//               gutterBottom
//               sx={{
//                 fontWeight: "850",
//                 fontSize: "19px",
//                 textTransform: "uppercase",
//                 mb: 3.5, // Reduced bottom margin
//                 textAlign: "left",
//               }}
//             >
//               LIMITED SERIES
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={8}>
//             <Divider
//               sx={{
//                 borderColor: "#d6dbd8",
//                 flexGrow: 1,
//                 fontWeight: "bold",
//                 // Adjust margin for responsiveness
//                 height: 1,
//                 mb: 4,
//                 width: isMobile ? "80%" : "100%", // Horizontal divider's height
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Typography
//           textAlign={"left"}
//           variant={isMobile ? "h4" : "h3"}
//           gutterBottom
//           sx={{
//             fontSize: isMobile ? "24px" : "48px",
//             fontWeight: "bold",
//             mb: 4,
//           }}
//         >
//           Future of TV series
//         </Typography>
//         <Typography
//           textAlign={"left"}
//           variant="body1"
//           sx={{
//             fontSize: "16px",
//             mb: 6,
//           }}
//         >
//           This video series explores the state of the streaming ad industry.
//         </Typography>
//         <Grid item xs={12} textAlign="left">
//           <Button
//             variant="contained"
//             color="primary"
//             sx={{
//               mb: 4,
//               fontSize: "14px",
//               fontWeight: "bold",
//               backgroundColor: "black",
//               color: "white",
//               "&:hover": {
//                 backgroundColor: "#FFFFFF", // Changes button background to white
//                 color: "#000000", // Changes text color to black
//               },
//             }}
//           >
//             WATCH NOW
//           </Button>
//         </Grid>
//         <Grid item xs={12}>
//           <Divider
//             sx={{
//               borderColor: "#d6dbd8",
//               height: 1,
//               mb: 4,
//             }}
//           />
//         </Grid>
//       </Grid>
//       {!isMobile && (
//         <Grid item xs={12} md={6}>
//           <img
//             src={abc}
//             alt="Future of TV"
//             style={{
//               width: "100%",
//               height: "auto",
//               maxHeight: "300px",
//               borderRadius: "1px",
//               marginBottom: "35px",
//             }}
//           />
//         </Grid>
//       )}
//     </Grid>
//   );
// }
function ImageSection3() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                     sx={{ 
                      marginLeft: 0.5, 
                      marginRight: 0.5,
                      color: "grey.900",        // Adjust the color to make the icon lighter
                      opacity: 0.7,             // Reduce opacity to soften the icon further
                    }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

// function ImageSection4() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent={"center"}
//       alignItems="center"
//       paddingTop={2}
//     >
//       <Grid item xs={12} md={6}>
//         <Grid container alignItems="center" spacing={1}>
//           <Grid item xs={12} md={4}>
//             <Typography
//               variant="subtitle1"
//               gutterBottom
//               sx={{
//                 fontWeight: "850",
//                 fontSize: "19px",
//                 textTransform: "uppercase",
//                 mb: 3.5, // Reduced bottom margin
//                 textAlign: "left",
//               }}
//             >
//               SPECIAL EDITION
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={8}>
//             <Divider
//               sx={{
//                 borderColor: "#d6dbd8",
//                 flexGrow: 1,
//                 fontWeight: "bold",
//                 // Adjust margin for responsiveness
//                 height: 1,
//                 mb: 4,
//                 width: isMobile ? "80%" : "100%", // Horizontal divider's height
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Typography
//           textAlign={"left"}
//           variant={isMobile ? "h4" : "h3"}
//           gutterBottom
//           sx={{
//             fontSize: isMobile ? "24px" : "48px",
//             fontWeight: "bold",
//             mb: 4.5, // Reduced bottom margin
//           }}
//         >
//           The third-party cookie primer
//         </Typography>
//         <Typography
//           textAlign={"left"}
//           variant="body1"
//           sx={{
//             fontSize: "16px",
//             mb: 6, // Reduced bottom margin
//           }}
//         >
//           This editorial package from Digiday will help marketers navigate the
//           phase-out of the third-party cookie in Google Chrome browsers.
//         </Typography>
//         <Grid item xs={12} textAlign="left">
//           <Button
//             variant="contained"
//             color="primary"
//             sx={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               backgroundColor: "black",
//               color: "white",
//               "&:hover": {
//                 backgroundColor: "#FFFFFF", // Changes button background to white
//                 color: "#000000", // Changes text color to black
//               },
//               mb: 5, // Adjusted bottom margin for proper spacing
//             }}
//           >
//             VIEW EXPERIENCE
//           </Button>
//         </Grid>
//         <Grid item xs={12} md={12} lg={11}>
//           <Divider
//             sx={{
//               borderColor: "#d6dbd8",
//               height: 1,
//               mt: 2,
//             }}
//           />
//         </Grid>
//       </Grid>
//       {!isMobile && (
//         <Grid item xs={12} md={6}>
//           <img
//             src={dolar}
//             alt="Cookie"
//             style={{
//               width: "100%",
//               height: "auto",
//               maxHeight: "400px",
//               borderRadius: "1px",
//             }}
//           />
//         </Grid>
//       )}
//     </Grid>
//   );
// }

function ImageSection5() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                      sx={{ 
                        marginLeft: 0.5, 
                        marginRight: 0.5,
                        color: "grey.900",        // Adjust the color to make the icon lighter
                        opacity: 0.7,             // Reduce opacity to soften the icon further
                      }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ImageSection6() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                  sx={{ 
                    marginLeft: 0.5, 
                    marginRight: 0.5,
                    color: "grey.900",        // Adjust the color to make the icon lighter
                    opacity: 0.7,             // Reduce opacity to soften the icon further
                  }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ImageSection7() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                     sx={{ 
                      marginLeft: 0.5, 
                      marginRight: 0.5,
                      color: "grey.900",        // Adjust the color to make the icon lighter
                      opacity: 0.7,             // Reduce opacity to soften the icon further
                    }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ImageSection8() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                    sx={{ 
                      marginLeft: 0.5, 
                      marginRight: 0.5,
                      color: "grey.900",        // Adjust the color to make the icon lighter
                      opacity: 0.7,             // Reduce opacity to soften the icon further
                    }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

function ImageSection9() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => handleClick(article.slug)}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                width: { xs: "30%", sm: 180, md: 220, lg: 250, xl: 330 },
                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={11} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                    {article.shortdescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={11} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex", // Flex display to keep icon and text on the same line
                      alignItems: "center", // Center the icon and text vertically
                      textAlign: "left",
                      mt: 5,
                      alignSelf: "flex-start", // Align to the start to position it on the left
                    }}
                  >
                    <RemoveRedEyeIcon
                    sx={{ 
                      marginLeft: 0.5, 
                      marginRight: 0.5,
                      color: "grey.900",        // Adjust the color to make the icon lighter
                      opacity: 0.7,             // Reduce opacity to soften the icon further
                    }} 
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: {
                  xs: "2px",
                  sm: "2px",
                  md: "1px",
                  lg: "20px",
                  xl: "9px",
                },

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

// function WorkLifeSection() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const featuredArticle = {
//     img: tightropeImage, // Use the imported image
//     category: "Talent",
//     title:
//       "‘It’s like they just rolled out of bed’: Employers boost business etiquette training",
//     description:
//       "Ideas around what constitutes socially acceptable behavior at work have shifted widely following the pandemic and in new hybrid arrangements.",
//   };

//   return (
//     <Grid
//       container
//       // gap={6}
//       justifyContent="space-between"
//       alignItems="flex-start"
//       paddingTop={2}
//       xs={12}
//       md={12}
//       paddingBottom={6}

//       // border="2px solid black"
//     >
//       <Grid item xs={12} md={3} textAlign="left">
//         <Box display="flex" alignItems="center" marginBottom={2}>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontStyle: "italic",
//               marginRight: 1,
//             }}
//           >
//             more from
//           </Typography>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontWeight: "bold",
//               marginRight: 1,
//             }}
//           >
//             WORKLIFE
//           </Typography>
//           <Divider
//             sx={{
//               borderColor: theme.palette.primary.main,
//               flexGrow: 1,
//               height: 2,
//             }}
//           />
//         </Box>

//         {categories.map((category, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: theme.palette.primary.main,
//                 marginBottom: 1,
//                 fontSize: 14,
//                 fontWeight: 700,
//               }}
//             >
//               {category}
//             </Typography>
//             {index < categories.length - 1 && (
//               <Divider
//                 sx={{
//                   borderColor: theme.palette.primary.main,
//                   marginBottom: 1,
//                 }}
//               />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4} textAlign={isMobile ? "center" : "left"}>
//         {articles.map((article, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="overline"
//               sx={{
//                 color: theme.palette.primary.main,
//                 textTransform: "uppercase",
//                 display: "block",
//                 marginBottom: 1,
//               }}
//             >
//               {article.category}
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{ marginBottom: 2, fontWeight: "bold" }}
//             >
//               {article.title}
//             </Typography>
//             {index < articles.length - 1 && (
//               <Divider sx={{ marginBottom: 2 }} />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4}>
//         <CardMedia
//           component="img"
//           height="200"
//           image={featuredArticle.img}
//           alt={featuredArticle.title}
//         />

//         <Typography
//           variant="overline"
//           sx={{
//             color: theme.palette.primary.main,
//             textTransform: "uppercase",
//             display: "block",
//             marginBottom: 1,
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.category}
//         </Typography>
//         <Typography
//           variant="h6"
//           sx={{ fontWeight: "bold", marginBottom: 2, textAlign: "left" }}
//         >
//           {featuredArticle.title}
//         </Typography>
//         <Typography
//           variant="body2"
//           color="text.secondary"
//           sx={{
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.description}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// }

// function WorkLifeSection2() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const featuredArticle = {
//     img: unnamed9Image, // Use the imported image
//     category: "Talent",
//     title:
//       "‘It’s like they just rolled out of bed’: Employers boost business etiquette training",
//     description:
//       "Ideas around what constitutes socially acceptable behavior at work have shifted widely following the pandemic and in new hybrid arrangements.",
//   };

//   return (
//     <Grid
//       container
//       justifyContent="space-between"
//       alignItems="flex-start"
//       paddingTop={2}
//       paddingBottom={6}
//     >
//       <Grid item xs={12} md={3} textAlign="left">
//         <Box display="flex" alignItems="center" marginBottom={2}>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontStyle: "italic",
//               marginRight: 1,
//             }}
//           >
//             more from
//           </Typography>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontWeight: "bold",
//               marginRight: 1,
//             }}
//           >
//             WORKLIFE
//           </Typography>
//           <Divider
//             sx={{
//               borderColor: theme.palette.primary.main,
//               flexGrow: 1,
//               height: 2,
//             }}
//           />
//         </Box>

//         {categories.map((category, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: theme.palette.primary.main,
//                 marginBottom: 1,
//                 fontSize: 14,
//                 fontWeight: 700,
//               }}
//             >
//               {category}
//             </Typography>
//             {index < categories.length - 1 && (
//               <Divider
//                 sx={{
//                   borderColor: theme.palette.primary.main,
//                   marginBottom: 1,
//                 }}
//               />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4} textAlign={isMobile ? "center" : "left"}>
//         {articles.map((article, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="overline"
//               sx={{
//                 color: theme.palette.primary.main,
//                 textTransform: "uppercase",
//                 display: "block",
//                 marginBottom: 1,
//               }}
//             >
//               {article.category}
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{ marginBottom: 2, fontWeight: "bold" }}
//             >
//               {article.title}
//             </Typography>
//             {index < articles.length - 1 && (
//               <Divider sx={{ marginBottom: 2 }} />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4}>
//         <CardMedia
//           component="img"
//           height="200"
//           image={featuredArticle.img}
//           alt={featuredArticle.title}
//         />

//         <Typography
//           variant="overline"
//           sx={{
//             color: theme.palette.primary.main,
//             textTransform: "uppercase",
//             display: "block",
//             marginBottom: 1,
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.category}
//         </Typography>
//         <Typography
//           variant="h6"
//           sx={{ fontWeight: "bold", marginBottom: 2, textAlign: "left" }}
//         >
//           {featuredArticle.title}
//         </Typography>
//         <Typography
//           variant="body2"
//           color="text.secondary"
//           sx={{
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.description}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// }

// function WorkLifeSection3() {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const featuredArticle = {
//     img: bakingcookiesImage, // Use the imported image
//     category: "Talent",
//     title:
//       "‘It’s like they just rolled out of bed’: Employers boost business etiquette training",
//     description:
//       "Ideas around what constitutes socially acceptable behavior at work have shifted widely following the pandemic and in new hybrid arrangements.",
//   };

//   return (
//     <Grid
//       container
//       justifyContent="space-between"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       <Grid item xs={12} md={3} textAlign="left">
//         <Box display="flex" alignItems="center" marginBottom={2}>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontStyle: "italic",
//               marginRight: 1,
//             }}
//           >
//             more from
//           </Typography>
//           <Typography
//             variant="h8"
//             sx={{
//               fontFamily: "Lora, serif",
//               fontWeight: "bold",
//               marginRight: 1,
//             }}
//           >
//             WORKLIFE
//           </Typography>
//           <Divider
//             sx={{
//               borderColor: theme.palette.primary.main,
//               flexGrow: 1,
//               height: 2,
//             }}
//           />
//         </Box>

//         {categories.map((category, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="h6"
//               sx={{
//                 color: theme.palette.primary.main,
//                 marginBottom: 1,
//                 fontSize: 14,
//                 fontWeight: 700,
//               }}
//             >
//               {category}
//             </Typography>
//             {index < categories.length - 1 && (
//               <Divider
//                 sx={{
//                   borderColor: theme.palette.primary.main,
//                   marginBottom: 1,
//                 }}
//               />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4} textAlign={isMobile ? "center" : "left"}>
//         {articles.map((article, index) => (
//           <React.Fragment key={index}>
//             <Typography
//               variant="overline"
//               sx={{
//                 color: theme.palette.primary.main,
//                 textTransform: "uppercase",
//                 display: "block",
//                 marginBottom: 1,
//               }}
//             >
//               {article.category}
//             </Typography>
//             <Typography
//               variant="body1"
//               sx={{ marginBottom: 2, fontWeight: "bold" }}
//             >
//               {article.title}
//             </Typography>
//             {index < articles.length - 1 && (
//               <Divider sx={{ marginBottom: 2 }} />
//             )}
//           </React.Fragment>
//         ))}
//       </Grid>

//       <Grid item xs={12} md={4}>
//         <CardMedia
//           component="img"
//           height="200"
//           image={featuredArticle.img}
//           alt={featuredArticle.title}
//         />

//         <Typography
//           variant="overline"
//           sx={{
//             color: theme.palette.primary.main,
//             textTransform: "uppercase",
//             display: "block",
//             marginBottom: 1,
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.category}
//         </Typography>
//         <Typography
//           variant="h6"
//           sx={{ fontWeight: "bold", marginBottom: 2, textAlign: "left" }}
//         >
//           {featuredArticle.title}
//         </Typography>
//         <Typography
//           variant="body2"
//           color="text.secondary"
//           sx={{
//             textAlign: "left",
//           }}
//         >
//           {featuredArticle.description}
//         </Typography>
//       </Grid>
//     </Grid>
//   );
// }

//   return (
//     <Grid
//       xs={12}
//       md={12}
//       lg={12}
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         mt={isMobile ? 7 : 20}
//         mb={4}
//         spacing={1}
//         border={"2px solid black"}

//         sx={{
//           backgroundColor: "#FFFFFF",
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//         p={3}
//       >
//         <Grid item xs={12} md={12} lg={4}>
//           <Divider
//             sx={{
//               orientation: "horizontal",
//               borderColor: "black",
//               flex: 1,
//               fontWeight: "bold",
//             }}
//           />
//         </Grid>
//         <Grid item xs={12} md={12} lg={4}>
//           <Card
//             sx={{
//               backgroundColor: "red",
//               color: "black",
//               justifyContent: "center",
//               textAlign: "center",
//               borderRadius: 0,
//               display: "flex",
//               alignItems: "center",
//               flexGrow: 0,
//               flexShrink: 0,
//             }}
//           >
//             <CardContent
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 height: "0.1px",
//                 mb: -0.9,
//                 mt: 0.5,
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 sx={{
//                   textAlign: "center",
//                   color: "white",
//                   fontSize: { xs: "0.75rem", sm: "0.95rem" },
//                   fontFamily: "Graphik",

//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 DIGIDAY+ MEMBER EXCLUSIVES
//               </Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} md={4} lg={4} mt={0}>
//           <Divider
//             sx={{
//               orientation: "horizontal",
//               borderColor: "black",
//               flex: 1,
//               fontWeight: "bold",
//               mb: { xs: 2, md: 0 },
//             }}
//           />
//         </Grid>

//         <Grid container mt={5} justifyContent="center" xs={12} md={12} lg={12}>
//           {cardContent.map((item, index) => (
//             <Grid
//               item
//               xs={12}
//               md={8}
//               lg={4}
//               paddingBottom={2}
//               key={index}
//               justifyContent="center"
//             >
//               <Link
//                 href={item.link}
//                 target="_blank"
//                 rel="noopener"
//                 underline="none"
//                 sx={{ textDecoration: "none", display: "block" }}
//               >
//                 <Card
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     boxShadow: 0,
//                     // marginBottom: 8,
//                     flexDirection: "row",
//                     flexWrap: { xs: "wrap", md: "nowrap" },
//                     justifyContent: { xs: "space-around", sm: "center" },
//                     width: { xs: "100%", md: "auto" },
//                   }}
//                 >
//                   {!isMobile && index > 0 && (
//                     <Divider
//                       orientation="vertical"
//                       flexItem
//                       // sx={{ marginX: { xs: 0, md: 2 }, marginY: { xs: 2, md: 0 } }}
//                     />
//                   )}
//                   <CardMedia
//                     component="img"
//                     image={item.img}
//                     alt={item.text}
//                     sx={{
//                       // height: { xs: 150, md: "50%" },
//                       width: { xs: 90, md: "40%" },
//                       objectFit: "cover",
//                       marginLeft: 2,
//                       marginRight: 3,
//                       marginBottom: 1,
//                     }}
//                   />
//                   <CardContent
//                     sx={{
//                       padding: 0,
//                       textAlign: "left",
//                       [theme.breakpoints.down("lg")]: {
//                         textAlign: "center",
//                       },
//                     }}
//                   >
//                     <Typography gutterBottom variant="h6" component="div">
//                       Installation Guide
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       {item.text}
//                     </Typography>
//                   </CardContent>
//                 </Card>
//               </Link>
//             </Grid>
//           ))}
//         </Grid>

//         <Grid item xs={12} md={12} lg={12} marginBottom={3}>
//           <Linkage />
//         </Grid>

//         <Grid
//           container
//           // spacing={7}
//           // gap={1}
//           mt={-6}
//           xs={12}
//           md={12}
//           lg={12}
//           sx={{ backgroundColor: "" }}
//         >
//           <Grid item xs={12} md={7} lg={8}>
//             <Grid
//               xs={12}
//               md={12}
//               lg={12}
//               component="img"
//               src={robo}
//               alt="Sample GIF"
//               sx={{
//                 height: "auto",
//                 mb: 0,
//                 width: { xs: "95%", md: "95%", lg: "100%" },
//               }}
//             />

//             <Linkage2 />
//             <ReadCard />
//             <ReadCard2 />
//             <ReadCard3 />
//           </Grid>

//           <Grid item xs={12} md={10} lg={4} paddingLeft={isMobile ? 0 : 4}>
//             <BlogPosts />
//           </Grid>

//           <Grid item xs={12} md={12} lg={12}>
//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "20px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                 }}
//               >
//                 A LOOK AT GOOGLE'S LATEST COOKIE EXTENSION
//                 <Grid
//                   item
//                   xs={12}
//                   md={7}
//                   lg={7}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       orientation: "horizontal",
//                       borderColor: "#d6dbd8",
//                       flex: 1,
//                       fontWeight: "bold",
//                       marginLeft: 4,
//                       // marginLeft: "20px",

//                       // mb: { xs: 0, md: 0 },
//                     }}
//                   />

//                   <Link
//                     href="#"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>
//             <ImageSection1 />
//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <ImageSection2 />

//             <Grid item xs={12} md={12} lg={12}>

//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "25px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   paddingTop: "75px",
//                 }}
//               >
//                 MEDIA
//                 <Grid
//                   item
//                   xs={12}
//                   md={11}
//                   lg={11}
//                   ml={0}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       fontWeight: "bold",
//                       marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
//                       height: 3, // Horizontal divider's height
//                     }}
//                   />

//                   <Link
//                     href="http://localhost:3000/med"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>

//             </Grid>
//             <ImageSection3 />
//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />
//             <ImageSection4 />
//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "25px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   paddingTop: "75px",
//                 }}
//               >
//                 Marketing
//                 <Grid
//                   item
//                   xs={12}
//                   md={10.5}
//                   lg={10.5}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       fontWeight: "bold",
//                       marginLeft: { xs: "9px", md: "22px" }, // Adjust margin for responsiveness
//                       height: 2, // Horizontal divider's height
//                     }}
//                   />

// <Link
//                     href="http://localhost:3000/market"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>

//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>

//             <ImageSection5 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "24px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   // paddingTop: "75px",
//                 }}
//               >
//                 FUTURE OF TV
//                 <Grid
//                   item
//                   xs={12}
//                   md={10.3}
//                   lg={10.3}
//                   ml={0}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       fontWeight: "bold",
//                       marginLeft: { xs: "16px", md: "20px" }, // Adjust margin for responsiveness
//                       height: 3, // Horizontal divider's height
//                     }}
//                   />

//                   <Link
//                     href="#"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>
//             <ImageSection6 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "17px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   // paddingTop: "75px",
//                 }}
//               >
//                 Media buying
//                 <Grid
//                   item
//                   xs={12}
//                   md={10.7}
//                   lg={10.7}
//                   ml={0}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       fontWeight: "bold",
//                       marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
//                       height: 2, // Horizontal divider's height
//                     }}
//                   />

//                   <Link
//                     href="#"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>
//             <ImageSection7 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />
//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "25px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   // paddingTop: "75px",
//                 }}
//               >
//                 PODCASTS
//                 <Grid
//                   item
//                   xs={12}
//                   md={10.6}
//                   lg={10.6}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       fontWeight: "bold",
//                       marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
//                       height: 3, // Horizontal divider's height
//                     }}
//                   />

//                   <Link
//                     href="#"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>
//             <ImageSection8 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <Grid item xs={12} md={12} lg={12}>
//               <Typography
//                 sx={{
//                   textAlign: "left",
//                   display: "flex",
//                   fontWeight: "850",
//                   fontSize: "25px",
//                   textTransform: "uppercase",
//                   paddingBottom: "35px",
//                   // paddingTop: "75px",
//                 }}
//               >
//                 Research
//                 <Grid
//                   item
//                   xs={12}
//                   md={10.6}
//                   lg={10.6}
//                   ml={0}
//                   sx={{ display: "flex", alignItems: "center" }}
//                 >
//                   <Divider
//                     sx={{
//                       borderColor: "#d6dbd8",
//                       flexGrow: 1,
//                       // fontWeight: "bold",
//                       marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
//                       height: 3, // Horizontal divider's height
//                     }}
//                   />

//                   <Link
//                     href="#"
//                     sx={{
//                       paddingLeft: "20px",
//                       textAlign: "left",
//                       fontSize: "12px", // Reduced text size
//                       // fontStyle: "italic",
//                       textDecoration: "none",
//                       color: "#000",
//                       "&:hover": {
//                         color: theme.palette.primary.main,
//                       },
//                     }}
//                   >
//                     View more >>
//                   </Link>
//                 </Grid>
//               </Typography>
//             </Grid>
//             <ImageSection9 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 mb: 6,
//                 justifyContent: "center",
//               }}
//             />
//             <WorkLifeSection />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 // fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <WorkLifeSection2 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 // fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />

//             <WorkLifeSection3 />

//             <Divider
//               sx={{
//                 orientation: "horizontal",
//                 borderColor: "#d6dbd8",
//                 flex: 1,
//                 // fontWeight: "bold",
//                 // marginLeft: "55px",
//                 mb: 6,
//                 justifyContent: "center",
//                 // mb: { xs: 0, md: 0 },
//               }}
//             />
//             <Footer />
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };

export const New = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);
  const [MostClickedArticle, setMostClickedArticle] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data.slice(0, 1);

        console.log(articlesArray);
        setArticle(articlesArray);

        const articlesSixArray = response.data;
        const mostClickedArticles = articlesSixArray
          .sort((a, b) => b.clicks - a.clicks) // Sort by clicks in descending order
          .slice(0, 3); // Take the top 3
        console.log("====================================");
        console.log(mostClickedArticles);
        console.log("====================================");
        // Set the state for most clicked articles
        setMostClickedArticle(mostClickedArticles);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };
  // const { slug } = useParams();

  return (
    <Grid
      // border={"2px solid black"}

      xs={12}
      md={12}
      lg={12}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />

      <Grid
        container
        xs={11}
        md={10}
        lg={10}
        mt={isMobile ? 7 : 20}
        mb={4}
        // spacing={1}
        // border={"2px solid black"}
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
        p={3}
      >
        <Grid item xs={12} md={2} lg={4}>
          <Divider
            sx={{
              orientation: "horizontal",
              borderColor: "black",
              // flex: 1,
              fontWeight: "bold",
            }}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Grid
            sx={{
              backgroundColor: "red",
              color: "black",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: 0,
              display: "flex",
              alignItems: "center",
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "0.1px",
                mb: -0.9,
                mt: 0.5,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  color: "white",
                  fontSize: { xs: "0.75rem", sm: "0.95rem" },
                  fontFamily: "Graphik",

                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                MARKETING INC+ MEMBER EXCLUSIVES
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} lg={4}>
          <Divider
            sx={{
              orientation: "horizontal",
              borderColor: "black",
              // flex: 1,
              fontWeight: "bold",
            }}
          />
        </Grid>

        <Grid container mt={5} justifyContent="center" xs={12} md={12} lg={12}>
          {MostClickedArticle.map((MostClickedArticle, index) => (
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              paddingBottom={2}
              key={index}
              justifyContent="center"
              onClick={() => handleClick(MostClickedArticle.slug)}
            >
              <Link
                href={MostClickedArticle.description}
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{
                  textDecoration: "none",
                  display: "block",
                  cursor: "pointer",
                }}
              >
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    boxShadow: 0,
                    // marginBottom: 8,
                    flexDirection: { xs: "row", md: "column", lg: "row" },
                    flexWrap: { xs: "wrap", md: "wrap", lg: "nowrap" },
                    justifyContent: {
                      xs: "space-around",
                      sm: "center",
                      md: "space-around",
                    },
                    width: { xs: "100%", md: "95%", lg: "auto" },
                    cursor: "pointer",
                  }}
                >
                  {!isMobile && index > 0 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      // sx={{ marginX: { xs: 0, md: 2 }, marginY: { xs: 2, md: 0 } }}
                    />
                  )}
                  <CardMedia
                    component="img"
                    image={MostClickedArticle.imagelink}
                    alt={MostClickedArticle.headertext}
                    // alt={article.text}
                    sx={{
                      // height: { xs: 150, md: "70%" },
                      width: { xs: 90, md: "70%", lg: "40%" },
                      objectFit: "cover",
                      marginLeft: 2,
                      marginRight: 3,
                      marginBottom: 1,
                      pointer: "cursor",
                    }}
                  />
                  <CardContent
                    sx={{
                      padding: 0,
                      textAlign: isMobile ? "center" : "left",
                      [theme.breakpoints.down("lg")]: {
                        textAlign: "left",
                        marginLeft: { xs: 3, md: 4, lg: 0 },
                      },
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div">
                      {MostClickedArticle.headertext}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      {MostClickedArticle.headertext}
                    </Typography> */}

                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex", // Flex display to keep icon and text on the same line
                        alignItems: "center", // Center the icon and text vertically
                        textAlign: "left",
                        mt: 5,
                        alignSelf: "flex-start", // Align to the start to position it on the left
                      }}
                    >
                      <RemoveRedEyeIcon
                        sx={{ 
                          marginLeft: 0.5, 
                          marginRight: 0.5,
                          color: "grey.900",        // Adjust the color to make the icon lighter
                          opacity: 0.7,             // Reduce opacity to soften the icon further
                        }} 
                      />
                      {MostClickedArticle.clicks}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} md={12} lg={12} marginBottom={3}>
          <Linkage />
        </Grid>

        <Grid
          container
          // spacing={7}
          // gap={1}
          mt={-6}
          xs={12}
          md={12}
          lg={12}
          sx={{ backgroundColor: "" }}
        >
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            // onClick={() => handleClick(article.slug)}
          >
            {/* <Grid
              xs={12}
              md={12}
              lg={12}
              component="img"
              image={article.imagelink}
              // alt={article.headertext}
              sx={{
                height: "auto",
                mb: 0,
                width: { xs: "100%", md: "95%", lg: "100%" },
              }}
            /> */}

            {article.length > 0 && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                onClick={() => handleClick(article.slug)}
              >
                <Link
                  sx={{
                    color: "black",
                    fontWeight: 800,
                    fontSize: "1.6rem",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleClick(article[0].slug)}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    component="img"
                    src={article[0].imagelink} // Display image from the 0th index
                    alt={article[0].headertext || "Article Image"}
                    sx={{
                      height: "auto",
                      mb: 0,
                      width: { xs: "100%", md: "95%", lg: "100%" },
                      objectFit: "cover",
                    }}
                  />

                  <Grid item xs={12}>
                    <Typography sx={{ mb: 6, textAlign: "center" }}>
                      {article[0].shortdescription}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
            )}

            {/* <Linkage2 /> */}

            <ReadCard />
            {/* <ReadCard2 />
            <ReadCard3 /> */}
          </Grid>
          <Grid item xs={12} md={4} lg={4} paddingLeft={isMobile ? 0 : 4}>
            <BlogPosts />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "20px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                }}
              >
                A LOOK AT GOOGLE'S LATEST COOKIE EXTENSION
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={7}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      orientation: "horizontal",
                      borderColor: "#d6dbd8",
                      flex: 1,
                      fontWeight: "bold",
                      marginLeft: 4,
                      // marginLeft: "20px",

                      // mb: { xs: 0, md: 0 },
                    }}
                  />

                  <Link
                    href="#"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection1 />
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />

            {/* <ImageSection2 /> */}

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "25px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  paddingTop: "75px",
                }}
              >
                MEDIA
                <Grid
                  item
                  xs={12}
                  md={11}
                  lg={11}
                  ml={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      fontWeight: "bold",
                      marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
                      height: 3, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="http://localhost:3000/blog"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection3 />
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />
            {/* <ImageSection4 /> */}
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "25px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  paddingTop: "75px",
                }}
              >
                Marketing
                <Grid
                  item
                  xs={12}
                  md={10.5}
                  lg={10.5}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      fontWeight: "bold",
                      marginLeft: { xs: "9px", md: "22px" }, // Adjust margin for responsiveness
                      height: 2, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="http://localhost:3000/blog"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>

            <ImageSection5 />

            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "24px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  // paddingTop: "75px",
                }}
              >
                FUTURE OF TV
                <Grid
                  item
                  xs={12}
                  md={10.3}
                  lg={10.3}
                  ml={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      fontWeight: "bold",
                      marginLeft: { xs: "16px", md: "20px" }, // Adjust margin for responsiveness
                      height: 3, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="#"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection6 />

            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "17px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  // paddingTop: "75px",
                }}
              >
                Media buying
                <Grid
                  item
                  xs={12}
                  md={10.7}
                  lg={10.7}
                  ml={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      fontWeight: "bold",
                      marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
                      height: 2, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="#"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection7 />

            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />
            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "25px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  // paddingTop: "75px",
                }}
              >
                PODCASTS
                <Grid
                  item
                  xs={12}
                  md={10.6}
                  lg={10.6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      fontWeight: "bold",
                      marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
                      height: 3, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="#"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection8 />

            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            />

            <Grid item xs={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "left",
                  display: "flex",
                  fontWeight: "850",
                  fontSize: "25px",
                  textTransform: "uppercase",
                  paddingBottom: "35px",
                  // paddingTop: "75px",
                }}
              >
                Research
                <Grid
                  item
                  xs={12}
                  md={10.6}
                  lg={10.6}
                  ml={0}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Divider
                    sx={{
                      borderColor: "#d6dbd8",
                      flexGrow: 1,
                      // fontWeight: "bold",
                      marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
                      height: 3, // Horizontal divider's height
                    }}
                  />

                  <Link
                    href="#"
                    sx={{
                      paddingLeft: "20px",
                      textAlign: "left",
                      fontSize: "12px", // Reduced text size
                      // fontStyle: "italic",
                      textDecoration: "none",
                      color: "#000",
                      "&:hover": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    View more
                  </Link>
                </Grid>
              </Typography>
            </Grid>
            <ImageSection9 />

            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                mb: 6,
                justifyContent: "center",
              }}
            />
            {/* <WorkLifeSection /> */}

            {/* <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                // fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            /> */}

            {/* <WorkLifeSection2 /> */}

            {/* <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                // fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            /> */}

            {/* <WorkLifeSection3 /> */}

            {/* <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#d6dbd8",
                flex: 1,
                // fontWeight: "bold",
                // marginLeft: "55px",
                mb: 6,
                justifyContent: "center",
                // mb: { xs: 0, md: 0 },
              }}
            /> */}
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default New;
