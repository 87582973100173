import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
// import Home from "./main/Home";
import NavBar from "./global/header";
import New from "./global/New";
import Footer from "./global/footer";
import SEO from "./main/SEO";
import Media from "./main/Media";
import Blogs from "./main/Blogs";
import Marketing from "./main/Marketing";
import Contactus from "./global/Contactus";
import About from "./global/About";
import Marketingnews from "./main/Marketingnews";
import Dashboard from "./admin/adminComponents/Dashboard";
import AddAuthor from "./admin/adminComponents/AddAuthor";
import AddCategories from "./admin/adminComponents/AddCategories";
// import  Article  from "./admin/adminComponents/AddArticle";
import Texteditor from "./admin/adminComponents/Texteditor";
import ArticlesTable from "./admin/adminComponents/ArticlesTable";
import AddArticleForm from "./admin/adminComponents/AddArticleForm";
import LoginForm from "./admin/adminComponents/LoginForm";
import Test from "./global/Test";
import SubscriberTable from "./admin/adminComponents/SubscriberTable";
import AuthorPage from "./main/AuthorPage";
import EditArticle from "./admin/adminComponents/EditArticle";


function App() {
  return (
    <div className="App">
      <main className="content">
        <Routes>
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/nav" element={<NavBar />} />
          <Route path="/" default element={<New />} />
          <Route path="/foot" element={<Footer />} />
          <Route path="/seo/:slug" element={<SEO />} />
          <Route path="/mediablog" element={<Media />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/market" element={<Marketing />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/about" element={<About />} />
          <Route path="/marketing" element={<Marketingnews />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addauthor" element={<AddAuthor />} />
          <Route path="/addcategories" element={<AddCategories />} />
          <Route path="/articlestable" element={<ArticlesTable />} />
          <Route path="/editor" element={<Texteditor />} />
          <Route path="/addarticle" element={<AddArticleForm />} />
          <Route path="/admin" element={<LoginForm />} />
          <Route path="/test" element={<Test />} />
          <Route path="/subscribe" element={<SubscriberTable />} />
          <Route path="/profile/:authorName" element={<AuthorPage />} />
          <Route path="/editarticle/:slug" element={<EditArticle />} />
          {/* <Route path="/articles/edit/:slug" element={<EditArticle />} /> */}

        </Routes>
      </main>
    </div>
  );
}

export default App;
