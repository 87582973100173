// import React, { useState, useEffect } from "react";
// import Typography from "@mui/material/Typography";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import NavBar from "../global/header";
// import { Box, Button, CardMedia, CardActions, Paper } from "@mui/material";
// import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
// import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
// import ContentMarketingImg from "../main/img/content-marketing.jpg";
// import BuisnessImg from "../main/img/Buisness.jpg";
// import DigitalImg from "../main/img/Digital.png";
// import EmailMarketingImg from "../main/img/email-marketing.jpg";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import Footer from "../global/footer";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// const items = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);





// function ArticleGrid({ articles }) {
//   return (
//     <Grid container spacing={4} mt={8} justifyContent="center">
//       {articles.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={4}
//           key={index}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <Link
//             to={`/seo/${article.slug}`}
//             variant="body2"
//             color="primary"
//             sx={{
//               display: "inline-block", // Use inline-block to ensure proper alignment without flex effects
//               textDecoration: "none",
//               textAlign: "left",
//               mt: 5,
//               border: "2px solid black",
//               fontWeight: "bold",
//             }}
//           >
//             <Card
//               sx={{
//                 height: 430, // Set a fixed height for all cards
//                 width: "100%", // Ensure full width within its container
//                 maxWidth: 345, // Optional: max width for responsive design
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between", // Align content within the card
//                 p: 2,
//               }}
//             >
//               <CardMedia
//                 component="img"
//                 src={article.imagelink}
//                 alt={article.headertext}
//                 sx={{
//                   height: 190, // Set a consistent height for images
//                 }}
//               />
//               <CardContent
//                 sx={{
//                   flexGrow: 1, // Ensure CardContent takes available space
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between", // Space text and button evenly
//                 }}
//               >
//                 <Grid
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                     mb: 1,
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     color="white"
//                     sx={{
//                       backgroundColor: "primary.light",
//                       borderRadius: 1,
//                       px: 1,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                 </Grid>
//                 <Typography
//                   variant="h6"
//                   sx={{
//                     textAlign: "left",
//                     fontWeight: "bold",
//                   }}
//                   gutterBottom
//                 >
//                   {article.headertext}
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   sx={{ textAlign: "left" }}
//                 >
//                   {article.date}
//                 </Typography>
//                 {/* <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   sx={{ textAlign: "left", mt: 1, fontWeight: "bold" }}
//                 >
//                   {article.shortdescription}
//                 </Typography> */}
//               </CardContent>
//               <CardActions
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between", // Space out actions
//                 }}
//               >
//                 <Link
//                   to={`/seo/${article.slug}`}
//                   variant="body2"
//                   color="primary"
//                   sx={{
//                     display: "inline-block",
//                     textDecoration: "none",
//                     textAlign: "left",
//                     mt: 5,
//                     border: "2px solid black",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   Read more 
//                   {/* <span style={{ marginRight: 210, color: "black" }}>»»</span> */}
//                 </Link>
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     textAlign: "left",
//                     mt: 5,
//                     alignSelf: "flex-start",
//                   }}
//                 >
//                   <RemoveRedEyeIcon
//                     sx={{
//                       marginLeft: 0.5,
//                       marginRight: 0.5,
//                       color: "grey.900",
//                       opacity: 0.7,
//                     }}
//                   />
//                   {article.clicks}
//                 </Typography>
//               </CardActions>
//             </Card>
//           </Link>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }



// function PaginatedList({ count }) {
//   const [page, setPage] = useState(1); // Start at page 1
//   const itemsPerPage = 25;

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(count / itemsPerPage); // Calculate total pages
// }

// export const Media = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticle] = useState([]);
//   const [articleCount, setArticleCount] = useState(0);

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId);
//   };

//   const [page, setPage] = useState(1); // Start at page 1
//   const itemsPerPage = 25;

//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const totalPages = Math.ceil(articleCount / itemsPerPage);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data;
//         setArticle(articlesArray);
//         const extractedCategories = Array.from(
//           new Set(response.data.map((article) => article.category))
//         )
//           .filter((category) => category !== "N/A") // Filter out "N/A" if necessary
//           .map((category, index) => ({
//             id: `category-${category.toLowerCase().replace(/\s+/g, "-")}`,
//             name: category,
//           }));

//         setArticleCount(articlesArray.length);
//         setCategories(extractedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const filteredArticles = selectedCategory
//     ? articles.filter((article) => article.tag === selectedCategory)
//     : articles;

//   console.log("====================================");
//   console.log(filteredArticles);
//   console.log("====================================");

//   return (
//     <Grid
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
//         <Typography
//           variant="h1"
//           component="h1"
//           sx={{
//             fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
//             fontWeight: "bold",
//             textAlign: "center",
//             color: "blue", // Attractive teal color
//           }}
//         >
//           Blogs
//         </Typography>
//       </Grid>
//       {/* 
//       <CategoriesBar
//         onCategorySelect={handleCategorySelect}
//         categories={categories}
//       /> */}

//       <Grid container xs={12} md={10} lg={10}>
//         <ArticleGrid articles={filteredArticles} />
//       </Grid>

//       {/* <PaginatedList count={articleCount}/> */}
//       <Pagination
//         count={totalPages} // Set the count to totalPages
//         page={page}
//         onChange={handleChange}
//         variant="text"
//         shape="circular"
//         color="primary"
//         siblingCount={1}
//         boundaryCount={1}
//         showFirstButton
//         showLastButton
//         sx={{
//           "& .MuiPaginationItem-root": {
//             fontSize: { xs: "0.75rem", sm: "1rem" },
//           },
//           mt: 2,
//         }}
//       />

//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default Media;


import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NavBar from "../global/header";
import { CardMedia, CardActions, Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Footer from "../global/footer";
import axios from "axios";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

function ArticleGrid({ articles }) {
  return (
    <Grid container spacing={4} mt={8} justifyContent="center">
      {articles.map((article, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link
            to={`/seo/${article.slug}`}
            style={{
              textDecoration: "none",
              width: "100%",
            }}
          >
            <Card
              sx={{
                height: 430,
                maxWidth: 345,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <CardMedia
                component="img"
                src={article.imagelink}
                alt={article.headertext}
                sx={{
                  height: 190,
                  objectFit: "cover",
                }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{
                    backgroundColor: "primary.light",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                    fontWeight: "bold",
                    mb: 1,
                    textAlign: "left",
                  }}
                >
                  {article.category}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    fontSize: "1.1rem",
                  }}
                >
                  {article.headertext}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "left", mt: 0.5 }}
                >
                  {article.date}
                </Typography>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 2,
                  pb: 1,
                }}
              >
                <Link
                  to={`/seo/${article.slug}`}
                  style={{
                    textDecoration: "none",
                    color: "#1976d2",
                    fontWeight: "bold",
                  }}
                >
                  Read more »
                </Link>
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "grey.800",
                  }}
                >
                  <RemoveRedEyeIcon
                    sx={{
                      mr: 0.5,
                      color: "grey.700",
                      fontSize: "1.1rem",
                    }}
                  />
                  {article.clicks}
                </Typography>
              </CardActions>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export const Media = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [articles, setArticle] = useState([]);
  const [articleCount, setArticleCount] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = 25;

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const totalPages = Math.ceil(articleCount / itemsPerPage);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data;
        setArticle(articlesArray);
        const extractedCategories = Array.from(
          new Set(response.data.map((article) => article.category))
        )
          .filter((category) => category !== "N/A")
          .map((category) => ({
            id: `category-${category.toLowerCase().replace(/\s+/g, "-")}`,
            name: category,
          }));

        setArticleCount(articlesArray.length);
        setCategories(extractedCategories);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const filteredArticles = selectedCategory
    ? articles.filter((article) => article.tag === selectedCategory)
    : articles;

  return (
    <Grid
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />
      <Grid item xs={12} md={10} lg={10} mt={isMobile ? 10 : 20}>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            fontWeight: "bold",
            textAlign: "center",
            color: "blue",
          }}
        >
          Blogs
        </Typography>
      </Grid>
      <Grid container xs={12} md={10} lg={10}>
        <ArticleGrid articles={filteredArticles} />
      </Grid>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChange}
        variant="text"
        shape="circular"
        color="primary"
        siblingCount={1}
        boundaryCount={1}
        showFirstButton
        showLastButton
        sx={{
          "& .MuiPaginationItem-root": {
            fontSize: { xs: "0.75rem", sm: "1rem" },
          },
          mt: 2,
        }}
      />
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Media;
