import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NavBar from "../global/header";
import {
  Box,
  Button,
  CardMedia,
  CardActions,
  Paper,
  Link,
  List,
  ListItem,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  AppBar,
} from "@mui/material";
//   import { useNavigate } from "react-router-dom";
import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
import ContentMarketingImg from "../main/img/content-marketing.jpg";
import BuisnessImg from "../main/img/Buisness.jpg";
import DigitalImg from "../main/img/Digital.png";
import seoImg from "../main/img/seo.jpg"; // Add this image
import SearchIcon from "@mui/icons-material/Search";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Footer from "../global/footer";

const articles = [
  {
    img: MobileMarketingImg,
    tag: "MOBILE MARKETING",
    title: "The Impact of 5G Technology on Mobile Marketing Strategies",
    date: "July 3, 2024",
    description:
      "The advent of 5G technology is set to revolutionize various industries, and ",
  },
  {
    img: SocialMediaMarketingImg,
    tag: "SOCIAL MEDIA MARKETING",
    title: "Creating Engaging Social Media Stories: Tips and Tools",
    date: "July 2, 2024",
    description:
      "Social media stories have become a dynamic way for brands to connect with their audience.",
  },
  {
    img: ContentMarketingImg,
    tag: "SEO",
    title: "What You Need to Know About Improving Your SEO Ranking",
    date: "July 1, 2024",
    description:
      "Improving Your SEO Ranking. In the ever-evolving landscape of digital marketing,",
  },
  {
    img: EmailMarketingImg,
    tag: "Email MARKETING",
    title: "The Impact of 5G Technology on Mobile Marketing Strategies",
    date: "July 3, 2024",
    description:
      "The advent of 5G technology is set to revolutionize various industries, and ",
  },
  {
    img: BuisnessImg,
    tag: "Business",
    title: "Creating Engaging Social Media Stories: Tips and Tools",
    date: "July 2, 2024",
    description:
      "Social media stories have become a dynamic way for brands to connect with their audience.",
  },
  {
    img: DigitalImg,
    tag: "Digital Marketing",
    title: "What You Need to Know About Improving Your SEO Ranking",
    date: "July 1, 2024",
    description:
      "Improving Your SEO Ranking. In the ever-evolving landscape of digital marketing,",
  },
  {
    img: MobileMarketingImg,
    tag: "MOBILE MARKETING",
    title: "The Impact of 5G Technology on Mobile Marketing Strategies",
    date: "July 3, 2024",
    description:
      "The advent of 5G technology is set to revolutionize various industries, and ",
  },
  {
    img: SocialMediaMarketingImg,
    tag: "SOCIAL MEDIA MARKETING",
    title: "Creating Engaging Social Media Stories: Tips and Tools",
    date: "July 2, 2024",
    description:
      "Social media stories have become a dynamic way for brands to connect with their audience.",
  },
];

function ArticleList() {
  return (
    <Grid item xs={12} md={12} lg={12} sx={{ mt: 4 }}>
      <List sx={{ width: "100%", maxWidth: 900 }}>
        {articles.map((article, index) => (
          <ListItem
            key={index}
            sx={{
              mb: -2, // Adjusted margin-bottom for spacing between cards
              p: 0, // Removed padding around ListItem to reduce spacing
              //   borderBottom: "1px solid #ddd", // Optional: Adds a border for better separation
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { md: "center" },
                // p: 2, // Added padding inside Grid for consistent spacing
              }}
            >
              <CardMedia
                component="img"
                src={article.img}
                alt={article.title}
                sx={{
                  width: { xs: "100%", md: 195 },
                  height: { xs: 150, md: 100 },
                  objectFit: "cover",
                  borderRadius: 0, // Optional: Adds rounded corners to the image
                }}
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "16px",
                  ml: { md: 2 }, // Added left margin for spacing between image and content
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Link
                    href="#"
                    variant="body2"
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      mt: 1,
                      fontSize: 14,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="white"
                      sx={{
                        borderRadius: 1,
                        px: 1,
                        py: 0.5,
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize: 12,
                        backgroundColor: "primary.light",
                        color: "white",
                      }}
                    >
                      {article.tag}
                    </Typography>
                  </Link>
                </Grid>
                <Link
                  href="#"
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    mt: 1,
                    fontSize: 14,
                    color: "black",
                    textDecorationThickness: "2px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "left", fontSize: 18, fontWeight: 600 }}
                    gutterBottom
                  >
                    {article.title}
                  </Typography>
                </Link>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "left", fontSize: 12, mt: 1 }}
                >
                  {article.date}
                </Typography>
              </CardContent>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

function BlogPosts() {
  const articles = [
    {
      id: 1,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "After years of uncertainty, Google says it won’t be ‘deprecating third-party cookies’ in Chrome",
      img: seoImg,
    },
    {
      id: 2,
      category: "BEYOND ADS",
      title:
        "Teads’ M&A rumors are firming up with a deal to merge with Outbrain",
      img: EmailMarketingImg,
    },
    {
      id: 3,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "Ad world is relieved but skeptical about Google’s decision to keep cookies in Chrome",
      img: DigitalImg,
    },
    {
      id: 4,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "Why Google’s cookie deprecation reversal isn’t actually a reprieve for publishers",
      img: seoImg,
    },
    {
      id: 5,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "After years of uncertainty, Google says it won’t be ‘deprecating third-party cookies’ in Chrome",
      img: EmailMarketingImg,
    },
    {
      id: 6,
      category: "BEYOND ADS",
      title:
        "Teads’ M&A rumors are firming up with a deal to merge with Outbrain",
      img: DigitalImg,
    },
    {
      id: 7,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "Ad world is relieved but skeptical about Google’s decision to keep cookies in Chrome",
      img: seoImg,
    },
    {
      id: 8,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "Why Google’s cookie deprecation reversal isn’t actually a reprieve for publishers",
      img: EmailMarketingImg,
    },
    {
      id: 9,
      category: "LIFE BEYOND THE COOKIE",
      title:
        "After years of uncertainty, Google says it won’t be ‘deprecating third-party cookies’ in Chrome",
      img: "../main/img/seo.jpg",
    },
    {
      id: 10,
      category: "BEYOND ADS",
      title:
        "Teads’ M&A rumors are firming up with a deal to merge with Outbrain",
      img: "/path/to/image2.jpg",
    },
  ];

  return (
    <Grid container sx={{ textAlign: "left" }}>
      {articles.map((article, index) => (
        <Grid key={article.id} sx={{ mt: 3 }}>
          <Grid container>
            <Grid item xs={1} sm={1}>
              <Typography variant="h6" sx={{ color: "grey" }}>
                {index + 1}
              </Typography>
            </Grid>

            <Grid item xs={7} sm={8}>
              <Typography variant="caption" sx={{ color: "grey" }}>
                {article.category}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {article.title}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Avatar
                variant="rounded"
                src={article.img}
                sx={{ width: 100, height: 100, objectFit: "fill" }}
              />
            </Grid>
          </Grid>
          {index < articles.length - 1 && <Divider sx={{ my: 2 }} />}
        </Grid>
      ))}
      {/* </Grid> */}
    </Grid>
  );
}

function MarketingBlogs() {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent="space-between"
      alignItems="center"

      // sx={{ padding: 4 }}
    >
      <Grid item xs={12} md={6} lg={6} sx={{ textAlign: "left" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#7F56D9" }}>
          Marketing blogs
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            mb: 3,
            color: "#344054",
            fontWeight: "600",
            fontSize: "20px",
          }}
        >
          Original content written by the Marketing Inc Editorial Team or from
          our contributing authors who are some of the stalwarts within the
          marketing and growth functions within their industries.
        </Typography>
        <Grid sx={{ display: "flex", alignItems: "center", width: "90%" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#F2F4F7",
                borderRadius: 1,
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        component="img"
        src={seoImg}
        alt="Blog"
        sx={{
          // maxWidth: "50%",
          height: "auto",
          mt: { xs: 4, md: 0 },
        }}
      />
    </Grid>
    // </Grid>
  );
}

const categories = [
  "Content Marketing",
  "Email Marketing",
  "Mobile Marketing",
  "Personal Marketing",
  "PPC",
  "SEO",
  "Social Media Marketing",
  "Uncategorized",
];

function CategoriesBar() {
  return (
    <Grid
      sx={{
        backgroundColor: "#6E85FF", // Adjust the color as needed
        padding: "40px 0",
        width: "100%", // Ensures the bar takes full width of the screen
        boxSizing: "border-box",
      }}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {categories.map((category, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md="auto"
            textAlign={{ xs: "center", md: "left" }}
          >
            <Link
              href="#"
              variant="body2"
              sx={{
                display: "flex",
                justifyContent: "center", // Center the content on mobile
                alignItems: "center",
                textDecoration: "none",
                fontSize: 14,
                color: "black",

                //   borderBottom: { xs: "1px solid black", md: "none" }, // Add black underline on mobile view
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {category}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export const Marketing = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    // <Grid
    //   xs={11}
    //   md={8}
    //   lg={8}
    //   sx={{
    //     backgroundColor: "#FFFFFF",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     border:'2px solid black'
    //   }}
    // >
    //   <NavBar />

    //   <Grid
    //     container
    //     xs={12}
    //     md={10}
    //     lg={10}
    //     mt={isMobile ? 7 : 20}
    //     mb={4}
    //     spacing={1}
    //     border={"2px solid black"}

    //     sx={{
    //       backgroundColor: "#FFFFFF",
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //     // pl={isMobile ? 0 : 0}
    //   >
    //     <Grid item xs={12} md={12} lg={12}>
    //       <MarketingBlogs />
    //     </Grid>
    //   </Grid>

    //   <Grid
    //     container
    //     xs={12}
    //     md={12}
    //     lg={12}
    //     sx={{ backgroundColor: "" }}
    //     border={"2px solid black"}
    //     mt={isMobile ? 7 : 5}
    //   >
    //     <Grid
    //       item
    //       xs={12}
    //       md={12}
    //       lg={12}
    //       // pl={isMobile ? 0 : 0}
    //       justifyContent={isMobile ? "center" : " none"}
    //     >
    //       <ThemeProvider theme={theme}>
    //         <CssBaseline />
    //         <CategoriesBar />
    //       </ThemeProvider>
    //     </Grid>
    //   </Grid>

    //   <Grid
    //     container
    //     // spacing={7}
    //     // gap={1}
    //     // mt={-6}
    //     xs={12}
    //     md={10}
    //     lg={10}
    //     sx={{ backgroundColor: "" }}
    //     // border={"2px solid black"}
    //     mt={isMobile ? 7 : 5}
    //   >
    //     <Grid container xs={12} md={8} lg={8}>
    //       <Grid
    //         item
    //         xs={12}
    //         md={12}
    //         lg={12}
    //         pl={isMobile ? 0 : 0}
    //         justifyContent={isMobile ? "center" : " none"}
    //       >
    //         <ArticleList />
    //       </Grid>
    //     </Grid>

    //     <Grid item xs={12} md={4} lg={4} paddingLeft={isMobile ? 0 : 0}>
    //       <BlogPosts />
    //     </Grid>
    //   </Grid>

    //   <Grid container xs={12} md={10} lg={10} mt={3} >
    //     <Footer />
    //   </Grid>
    // </Grid>
    <Grid
      // xs={11}
      // md={8}
      // lg={8}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // border:'2px solid black',
      }}
    >
      <NavBar />

      <Grid container xs={11} md={10} lg={10} mt={20}>
        <MarketingBlogs />
      </Grid>

      <CategoriesBar />

      <Grid
        container
        xs={11}
        md={10}
        lg={10}
        // sx={{ justifyContent: "center" }}
        // border="2px solid black"
      >
        <Grid item xs={12} md={8} lg={8}>
          <ArticleList />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <BlogPosts />
        </Grid>

        <Grid item xs={12} md={12} lg={12} mt={3}>
        <Footer />
      </Grid>
      </Grid>

     
    </Grid>
  );
};

export default Marketing;
