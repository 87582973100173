import React from "react";
import { Grid, Typography, Box, Link, Button } from "@mui/material";
import NavBar from "./header";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import aboutImg from "../main/img/about.jpg";
import Footer from "./footer";
import { styled } from "@mui/system";

const contentItems = [
  {
    title: "SEO Strategies",
    description:
      "Stay ahead of the curve with expert insights on search engine optimization, algorithm updates, and best practices.",
  },
  {
    title: "Growth Hacking Techniques",
    description:
      "Unleash rapid growth with the latest growth hacking tactics, tools, and case studies.",
  },
  {
    title: "Cold Emailing",
    description:
      "Master the art of cold outreach with actionable tips and proven strategies to generate leads and sales.",
  },
  {
    title: "Social Media Mastery",
    description:
      "Explore the power of social media marketing to build your brand, engage your audience, and drive traffic.",
  },
  {
    title: "Paid Marketing",
    description:
      "Learn how to optimize your digital advertising campaigns for maximum ROI and conversions.",
  },
  {
    title: "Conversion Rate Optimization",
    description:
      "Discover the secrets of CRO to turn website visitors into loyal customers.",
  },
  {
    title: "SaaS Marketing",
    description:
      "Get insider knowledge on how to successfully market software as a service (SaaS) products and grow your user base.",
  },
  {
    title: "Startup Marketing",
    description:
      "Find out how to effectively market your startup and scale your business.",
  },
  {
    title: "Leadership and Strategy",
    description:
      "Gain insights from CMOs and marketing leaders on strategic planning, team management, and driving growth at the enterprise level.",
  },
];

const Root = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4, 0),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2, 0),
  },
}));
const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));
const SubHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));
const ButtonContainer = styled(Grid)(({ theme }) => ({
  
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
   
  },
}));
const Foote = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      xs={12}
      md={8}
      lg={8}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />
      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        mt={3}
        p={isMobile ? 3 : "40px"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
        mt={isMobile ? 8 : 12}
        // border="2px solid black"
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item mb={isMobile ? 5 : 4}>
            <Typography
              variant="h1"
              fontSize={"70px"}
              color="#6986ED"
              fontWeight="bold"
              textAlign={isMobile ? "center" : "left"}
            >
              Welcome to Marketing Inc!
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="body1"
              component="p"
              sx={{ textAlign: "left" }}
            >
              Marketing Inc is your ultimate digital magazine and one-stop
              destination for all things marketing. Founded in 2023, we are a
              platform dedicated to empowering marketers of all stripes—from
              growth hackers and SEO gurus to cold emailers and social media
              experts. Whether you’re a paid marketing ninja, a conversion rate
              optimization (CRO) expert, a SaaS marketer, a startup marketing
              enthusiast, a Chief Marketing Officer (CMO) of an enterprise
              company, or simply someone interested in the vast world of
              marketing, Marketing Inc has something for you.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Grid
            component="img"
            src={aboutImg} // Replace with your image path
            alt="Contact Us"
            sx={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        backgroundColor={"#f5f5f5"}
        justifyContent={"center"}
        p={isMobile ? 3 : "40px"}
      >
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          //   justifyContent={"center"}
          //   alignItems={"center"}
          //   border={"2px solid black"}
          //   backgroundColor={"#f5f5f5"}
        >
          <Typography
            variant="h1"
            sx={{ textAlign: "left" }}
            fontSize={"30px"}
            color={"#00001b"}
            padding={"5px"}
            fontFamily={"Popins,sans-serif"}
            fontWeight={800}
            lineHeight={"40px"}
          >
            Our Story
          </Typography>

          <Typography
            variant="h5"
            mt={1}
            sx={{ fontWeight: "400px", textAlign: "left" }}
            fontSize={"16px"}
            fontFamily={"Popins,sans-serif"}
            color={"black"}
            padding={"5px"}
          >
            Marketing Inc was born out of a shared passion for the dynamic and
            ever-changing field of marketing. Our founding team, consisting of
            marketing experts with diverse specializations, came together with a
            vision to create a platform where marketing professionals from all
            walks of life can come together to share knowledge, insights, and
            inspiration. With this vision in mind, Marketing Inc quickly became
            a trusted resource for our community of marketing enthusiasts.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        justifyContent={"center"}
        padding={"40px"}
      >
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          sx={{
            padding: theme.spacing(0),
            // maxWidth: 800,
            margin: "0 auto",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            color="#00001b"
            fontWeight="bold"
            gutterBottom
          >
            Our Content
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph>
            At Marketing Inc, we believe that the best marketers never stop
            learning. That’s why we’re committed to delivering high-quality
            content that covers the full spectrum of marketing topics. Our
            digital magazine features:
          </Typography>
          {contentItems.map((item, index) => (
            <Grid key={index} sx={{ marginBottom: theme.spacing(2) }}>
              <Typography variant="h6" component="h3" fontWeight="bold">
                {item.title}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {item.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{ textAlign: "left" }}
        justifyContent={"center"}
        padding={"40px"}
        backgroundColor={"#f5f5f5"}

      >
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          //   justifyContent={"center"}
          //   alignItems={"center"}
          //   border={"2px solid black"}
          //   backgroundColor={"#f5f5f5"}
        >
          <Typography
            variant="h1"
            sx={{ textAlign: "left" }}
            fontSize={"30px"}
            color={"#00001b"}
            padding={"5px"}
            fontFamily={"Popins,sans-serif"}
            fontWeight={800}
            lineHeight={"40px"}
          >
            Our community
          </Typography>

          <Typography
            variant="h5"
            mt={1}
            sx={{ fontWeight: "400px", textAlign: "left" }}
            fontSize={"16px"}
            fontFamily={"Popins,sans-serif"}
            color={"black"}
            padding={"5px"}
          >
            Marketing Inc is more than just a digital magazine—it’s a community
            of marketing professionals who are passionate about their craft. Our
            readers are innovators, trendsetters, and game-changers who thrive
            on staying ahead of the curve. We provide a collaborative space
            where our community can connect, network, and share ideas.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        justifyContent={"center"}
        p={isMobile ? 3 : "40px"}
      >
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          //   justifyContent={"center"}
          //   alignItems={"center"}
          //   border={"2px solid black"}
          //   backgroundColor={"#f5f5f5"}
        >
          <Header variant="h4" sx={{ textAlign: "left" }}>
            Join Marketing Inc
          </Header>
          <SubHeader variant="body1" sx={{ textAlign: "left",mt:3 }}>
            Are you ready to level up your marketing game? Join Marketing Inc
            and become part of our vibrant community. Subscribe to our digital
            magazine and stay informed with the latest trends, strategies, and
            insights in the world of marketing.
          </SubHeader>
          <SubHeader variant="body1" sx={{ textAlign: "left" }}>
            Take the next step in your marketing journey.{" "}
            <strong>Join Marketing Inc today.</strong>
          </SubHeader>

          <ButtonContainer>
            <Grid container xs={12} md={12} lg={12} justifyContent={"space-around"} alignItems='center'>
              <Grid item xs={12} md={4} lg={4}>
                <Button variant="contained" color="primary" size="large" sx={{borderRadius:5 , border:"2px solid white"}}> 
                  Subscribe Now
                </Button>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Button variant="contained" color="primary" size="large" sx={{borderRadius:5, border:"2px solid white",marginTop: isMobile ? 2 : 0}}>
                  Join Our Community
                </Button>
              </Grid>

              <Grid item xs={12} md={4} lg={4} justifyContent={"right"}>
                <Button variant="contained" color="primary" size="large" sx={{borderRadius:5, border:"2px solid white" ,marginTop: isMobile ? 2 : 0}}>
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </ButtonContainer>

          <Foote variant="body1">
            Welcome to the future of marketing. Welcome to Marketing Inc.
          </Foote>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        mt={3}
        p={isMobile ? 3 : 0}
        spacing={2}
      >
        <Footer />
      </Grid>
    </Grid>
  );
};

export default About;
