// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   Select,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Snackbar,
//   SnackbarContent,
//   IconButton,
// } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { Editor } from "@tinymce/tinymce-react";
// import { Cancel as CancelIcon } from "@mui/icons-material";

// export const EditArticle = () => {
//   const { slug } = useParams();
//   useEffect(() => {
//     console.log(slug);
//   }, [slug]);
//   const navigate = useNavigate();

//   const initialValues = {
//     headertext: "",
//     metadescription: "",
//     shortdescription: "",
//     imagelink: "",
//     category: "",
//     author: "",
//     body: "",
//   };

//   const [articleData, setArticleData] = useState(initialValues);
//   const [authors, setAuthors] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [imageFile, setImageFile] = useState(null);

//   // Function to fetch article data
//   const fetchArticleData = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/cards/getonearticles/${slug}`
//       );
//       setArticleData(response.data);
//     } catch (error) {
//       console.error("Error fetching article data:", error);
//       setSnackbarMessage("Failed to fetch article data.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchAuthors = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_URL}/api/link`);
//       setAuthors(response.data.authors || []);
//     } catch (error) {
//       console.error("Error fetching authors:", error);
//       setSnackbarMessage("Failed to fetch authors.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     }
//   };

//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_URL}/api/getcategories`
//       );
//       setCategories(response.data.categories || []);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//       setSnackbarMessage("Failed to fetch categories.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     }
//   };

//   //   // Fetch all data on component mount
//   useEffect(() => {
//     fetchArticleData();
//     fetchAuthors();
//     fetchCategories();
//   }, []);

//   // Handle input changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setArticleData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   // Handle editor content change
//   const handleEditorChange = (content) => {
//     setArticleData((prevData) => ({ ...prevData, body: content }));
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   setLoading(true);

//   //   try {
//   //     let updatedArticleData = { ...articleData };

//   //     // Handle image file upload
//   //     if (imageFile) {
//   //       const formData = new FormData();
//   //       formData.append("file", imageFile);
//   //       const uploadResponse = await axios.post(
//   //         `${process.env.REACT_APP_URL}/api/cards/upload`,
//   //         formData
//   //       );
//   //       updatedArticleData.imagelink = uploadResponse.data.imageUrl;
//   //     }

//   //     // Check and log slug value before making the request
//   //     console.log("Slug value:", slug);

//   //     // Properly construct the API URL and log it
//   //     const apiUrl = `${process.env.REACT_APP_URL}/api/cards/articles/${slug}`;
//   //     console.log("API URL:", apiUrl);

//   //     console.log(updatedArticleData)
//   //     // Update article data
//   //     // debugger
//   //     const response = await axios.put(apiUrl, updatedArticleData);

//   //     // if (response.status === 200) {
//   //     //   setSnackbarMessage("Article updated successfully");
//   //     //   setSnackbarSeverity("success");
//   //     //   navigate("/articles");
//   //     // }

//   //     if (response.status === 200) {
//   //       setSnackbarMessage("Article updated successfully");
//   //       setSnackbarSeverity("success");
//   //       setSnackbarOpen(true);

//   //       // Navigate to articles table page after a short delay
//   //       setTimeout(() => {
//   //         navigate("/articlestable"); // Update this path based on your routing configuration
//   //       }, 1500); // Delay to show the snackbar message
//   //     }
//   //   } catch (error) {
//   //     console.error("Error updating article:", error);
//   //     setSnackbarMessage("Failed to update the article. Please try again.");
//   //     setSnackbarSeverity("error");
//   //   } finally {
//   //     setLoading(false);
//   //     setSnackbarOpen(true);
//   //   }
//   // };

//   // Handle image link cancellation

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       let updatedArticleData = { ...articleData };

//       // Handle image file upload
//       if (imageFile) {
//         const formData = new FormData();
//         formData.append("file", imageFile);
//         const uploadUrl = `${process.env.REACT_APP_URL}/api/cards/upload`;

//         console.log("Attempting to upload to:", uploadUrl);

//         // Make sure the URL and method are correct
//         const uploadResponse = await axios.post(uploadUrl, formData);
//         updatedArticleData.imagelink = uploadResponse.data.imageUrl;
//       }

//       // Log slug and API URL to verify correct data
//       console.log("Slug value:", slug);
//       const apiUrl = `${process.env.REACT_APP_URL}/api/cards/articles/${slug}`;
//       console.log("API URL:", apiUrl);

//       console.log("Updated Article Data:", updatedArticleData);

//       // Update article data
//       const response = await axios.put(apiUrl, updatedArticleData);

//       if (response.status === 200) {
//         setSnackbarMessage("Article updated successfully");
//         setSnackbarSeverity("success");
//         setSnackbarOpen(true);

//         // Navigate after showing snackbar message
//         setTimeout(() => {
//           navigate("/articlestable");
//         }, 1500);
//       }
//     } catch (error) {
//       console.error("Error updating article:", error);
//       setSnackbarMessage("Failed to update the article. Please try again.");
//       setSnackbarSeverity("error");
//     } finally {
//       setLoading(false);
//       setSnackbarOpen(true);
//     }
//   };

//   const handleImageLinkCancel = () => {
//     setArticleData((prevData) => ({ ...prevData, imagelink: "" }));
//     setImageFile(null);
//   };

//   // Handle file selection change
//   const handleFileChange = (e) => {
//     if (e.target.files[0]) {
//       setImageFile(e.target.files[0]);
//       const file = URL.createObjectURL(e.target.files[0]);
//       setArticleData((prevData) => ({ ...prevData, imagelink: file }));
//     }
//   };

//   // Handle image upload in the editor
//   const handleEditorImageUpload = (blobInfo, success, failure) => {
//     const formData = new FormData();
//     formData.append("file", blobInfo.blob(), blobInfo.filename());

//     axios
//       .post(`${process.env.REACT_APP_URL}/api/upload`, formData)
//       .then((response) => {
//         success(response.data.imageUrl);
//       })
//       .catch((error) => {
//         console.error("Error uploading image:", error);
//         failure("Image upload failed");
//       });
//   };

//   const initEditorConfig = {
//     height: 500,
//     plugins:
//       "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
//     toolbar:
//       "undo redo | formatselect | bold italic | link image removeimage | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
//     images_upload_handler: handleEditorImageUpload,
//     setup: (editor) => {
//       editor.ui.registry.addButton("removeimage", {
//         text: "Remove Image",
//         onAction: () => {
//           const selectedNode = editor.selection.getNode();
//           if (selectedNode.nodeName === "IMG") {
//             editor.execCommand("mceRemoveNode", false, selectedNode);
//           }
//         },
//       });
//     },
//   };

//   if (loading) return <CircularProgress />;

//   return (
//     <Container>
//       <Typography variant="h4" gutterBottom>
//         Edit Article
//       </Typography>

//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={6}>
//             <TextField
//               fullWidth
//               label="Article Header"
//               name="headertext"
//               value={articleData.headertext}
//               onChange={handleChange}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <TextField
//               fullWidth
//               label="Category"
//               name="category"
//               value={articleData.category}
//               onChange={handleChange}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <TextField
//               fullWidth
//               label="Author"
//               name="author"
//               value={articleData.author}
//               onChange={handleChange}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <TextField
//               fullWidth
//               label="Meta Description"
//               name="metadescription"
//               value={articleData.metadescription}
//               onChange={handleChange}
//               required
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="Short Description"
//               name="shortdescription"
//               value={articleData.shortdescription}
//               onChange={handleChange}
//               required
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               fullWidth
//               label="Image Link"
//               name="imagelink"
//               value={articleData.imagelink}
//               onChange={handleChange}
//               required
//               InputProps={{
//                 endAdornment: (
//                   <Button
//                     variant="contained"
//                     component="label"
//                     style={{ marginLeft: 10 }}
//                   >
//                     Upload
//                     <input type="file" hidden onChange={handleFileChange} />
//                   </Button>
//                 ),
//               }}
//             />
//             {articleData.imagelink && (
//               <div style={{ marginTop: "10px", position: "relative" }}>
//                 <img
//                   src={articleData.imagelink}
//                   alt="Article Preview"
//                   style={{ maxWidth: "100%", height: "auto" }}
//                 />
//                 <IconButton
//                   onClick={handleImageLinkCancel}
//                   style={{
//                     position: "absolute",
//                     top: "5px",
//                     right: "5px",
//                     backgroundColor: "rgba(0, 0, 0, 0.5)",
//                     color: "#fff",
//                   }}
//                 >
//                   <CancelIcon />
//                 </IconButton>
//               </div>
//             )}
//           </Grid>
//           <Grid item xs={12}>
//             <Editor
//               apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
//               value={articleData.body}
//               init={initEditorConfig}
//               onEditorChange={handleEditorChange}
//             />
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             style={{ display: "flex", justifyContent: "space-between" }}
//           >
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               disabled={loading}
//             >
//               {loading ? "Updating..." : "Update Article"}
//             </Button>

//             <Button
//               variant="outlined"
//               onClick={() => navigate(-1)}
//               style={{ marginBottom: 20 }}
//             >
//               Back
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <SnackbarContent
//           style={{
//             backgroundColor: snackbarSeverity === "success" ? "green" : "red",
//           }}
//           message={snackbarMessage}
//           action={[
//             <IconButton
//               key="close"
//               aria-label="close"
//               color="inherit"
//               onClick={() => setSnackbarOpen(false)}
//             >
//               <CancelIcon />
//             </IconButton>,
//           ]}
//         />
//       </Snackbar>
//     </Container>
//   );
// };

// export default EditArticle;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Cancel as CancelIcon } from "@mui/icons-material";

export const EditArticle = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const initialValues = {
    headertext: "",
    metadescription: "",
    shortdescription: "",
    imagelink: "",
    category: "",
    author: "",
    body: "",
  };

  const [articleData, setArticleData] = useState(initialValues);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchArticleData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/getonearticles/${slug}`
        );
        setArticleData(response.data);
      } catch (error) {
        console.error("Error fetching article data:", error);
        setSnackbarMessage("Failed to fetch article data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    const fetchAuthors = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/link`
        );
        setAuthors(response.data.authors || []);
      } catch (error) {
        console.error("Error fetching authors:", error);
        setSnackbarMessage("Failed to fetch authors.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getcategories`
        );
        setCategories(response.data.categories || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setSnackbarMessage("Failed to fetch categories.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    fetchArticleData();
    fetchAuthors();
    fetchCategories();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditorChange = (content) => {
    setArticleData((prevData) => ({ ...prevData, body: content }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     let updatedArticleData = { ...articleData };

  //     // Handle image file upload
  //     if (imageFile) {
  //       const formData = new FormData();
  //       formData.append("file", imageFile);
  //       const uploadResponse = await axios.post(
  //         `${process.env.REACT_APP_URL}/api/cards/upload`,
  //         formData
  //       );
  //       updatedArticleData.imagelink = uploadResponse.data.imageUrl;
  //     }

  //     const apiUrl = `${process.env.REACT_APP_URL}/api/cards/articles/${slug}`;
  //     const response = await axios.put(apiUrl, updatedArticleData);

  //     if (response.status === 200) {
  //       setSnackbarMessage("Article updated successfully");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);

  //       // Navigate after showing snackbar message
  //       setTimeout(() => {
  //         navigate("/articlestable");
  //       }, 1500);
  //     }
  //   } catch (error) {
  //     console.error("Error updating article:", error);
  //     setSnackbarMessage("Failed to update the article. Please try again.");
  //     setSnackbarSeverity("error");
  //   } finally {
  //     setLoading(false);
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let updatedArticleData = { ...articleData };

      // Handle image file upload
      if (imageFile) {
        const formData = new FormData();
        formData.append("imagelink", imageFile); // Ensure the file key is 'imagelink'
        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Ensure the correct headers are set
            },
          }
        );
        updatedArticleData.imagelink = uploadResponse.data.imageUrl; // Correctly set the image link from the response
      }

      const apiUrl = `${process.env.REACT_APP_URL}/api/cards/articles/${slug}`;
      const response = await axios.put(apiUrl, updatedArticleData);

      if (response.status === 200) {
        setSnackbarMessage("Article updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        // Navigate after showing snackbar message
        setTimeout(() => {
          navigate("/articlestable");
        }, 1500);
      }
    } catch (error) {
      console.error("Error updating article:", error);
      setSnackbarMessage("Failed to update the article. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleImageLinkCancel = () => {
    setArticleData((prevData) => ({ ...prevData, imagelink: "" }));
    setImageFile(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const file = URL.createObjectURL(e.target.files[0]);
      setArticleData((prevData) => ({ ...prevData, imagelink: file }));
    }
  };

  const initEditorConfig = {
    height: 500,
    plugins:
      "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
    toolbar:
      "undo redo | formatselect | bold italic | link image removeimage | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
    images_upload_handler: (blobInfo, success, failure) => {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      axios
        .post(`${process.env.REACT_APP_URL}/api/cards/upload`, formData)
        .then((response) => {
          success(response.data.imageUrl);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          failure("Image upload failed");
        });
    },
    setup: (editor) => {
      editor.ui.registry.addButton("removeimage", {
        text: "Remove Image",
        onAction: () => {
          const selectedNode = editor.selection.getNode();
          if (selectedNode.nodeName === "IMG") {
            editor.execCommand("mceRemoveNode", false, selectedNode);
          }
        },
      });
    },
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Edit Article
      </Typography>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "Right" }} // Position at the bottom center
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{
            backgroundColor: snackbarSeverity === "success" ? "green" : "red",
          }}
        />
      </Snackbar>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Article Header"
              name="headertext"
              value={articleData.headertext}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Category"
              name="category"
              value={articleData.category}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Author"
              name="author"
              value={articleData.author}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Meta Description"
              name="metadescription"
              value={articleData.metadescription}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Short Description"
              name="shortdescription"
              value={articleData.shortdescription}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Image Link"
              name="imagelink"
              value={articleData.imagelink}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    component="label"
                    style={{ marginLeft: 10 }}
                  >
                    Upload
                    <input type="file" hidden onChange={handleFileChange} />
                  </Button>
                ),
              }}
            />
            {articleData.imagelink && (
              <div style={{ marginTop: "10px", position: "relative" }}>
                <img
                  src={articleData.imagelink}
                  alt="Article Preview"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <IconButton
                  onClick={handleImageLinkCancel}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <Editor
              apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
              value={articleData.body}
              init={initEditorConfig}
              onEditorChange={handleEditorChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Saving..." : "Update Article"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/articlestable")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{
            backgroundColor: snackbarSeverity === "success" ? "green" : "red",
          }}
        />
      </Snackbar> */}

      {/* <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position at the bottom center
>
  <SnackbarContent
    message={snackbarMessage}
    style={{
      backgroundColor: snackbarSeverity === "success" ? "green" : "red",
    }}
  />
</Snackbar> */}
    </Container>
  );
};

export default EditArticle;
