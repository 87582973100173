import React from "react";
import { Container, Grid, Typography, Box, Link } from "@mui/material";
// import DigitalImg from "../main/img/Digital.png";
import contactImg from "../main/img/contact.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import Footer from "./footer";

import NavBar from "./header";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const Contactus = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      xs={12}
      md={8}
      lg={8}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />
      return (
    
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0} justifyContent={"center"} alignItems={"center"}

         spacing={2}
          mt={isMobile ? 8 : 18}
          // border="2px solid black"
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography
              variant="body1"
              component="p"
              sx={{ textAlign: "left" }}
            >
              At Marketing Inc, we value our community and are always eager to
              hear from you. Whether you have a question, feedback, or simply
              want to get in touch with our team, we’re here to help. Use the
              contact information and form below to reach out to us, and we’ll
              be sure to respond as soon as possible.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid
              component="img"
              src={contactImg} // Replace with your image path
              alt="Contact Us"
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}
        spacing={2} alignItems="center"  
          // border="2px solid black"
          >
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: "#5b73ff", fontWeight: "bold", textAlign: "left" }}
            >
              Connect with us
            </Typography>
            <Box
              sx={{
                borderBottom: "3px solid #5b73ff",
                width: "120px",
                mb: 3,
                // mx: isMobile ? "auto" : 0,
              }}
            />

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ mt: 2 }}
              // border={"2px solid black"}
              // textAlign={isMobile ? 'center' : 'left'}
            >
              <Grid item>
                <LocationOnIcon fontSize="large" sx={{ color: "#5b73ff" }} />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Address:
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "left" }}>
                  8350 Bee Ridge Suite #157 Sarasota, FL 34241 United States
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ mt: 2 }}
              // border={"2px solid black"}
            >
              <Grid item>
                <EmailIcon fontSize="large" sx={{ color: "#5b73ff" }} />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Email:
                </Typography>
                <Typography variant="body1">
                  <Link href="mailto:info@marketing.inc" color="inherit">
                    info@marketing.inc
                  </Link>
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ mt: 2 }}
              // border={"2px solid black"}
            >
              <Grid item>
                <PhoneIcon fontSize="large" sx={{ color: "#5b73ff" }} />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                >
                  Phone:
                </Typography>
                <Typography variant="body1">(860) 990-2447</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* </Grid> */}

          <Grid item xs={12} md={6}>
            <Grid
              component="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.6125116213586!2d-82.48061848488937!3d27.31311808305516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c33d92d5e8a031%3A0x4a2e1b7e95f9628d!2s8350%20Bee%20Ridge%20Rd%20%23157%2C%20Sarasota%2C%20FL%2034241%2C%20USA!5e0!3m2!1sen!2sin!4v1603910109051!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </Grid>
        </Grid>
      
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0} spacing={2}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Contactus;
