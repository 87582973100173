import { Box } from '@mui/system'
import React from 'react'
import DrawerHeader from '../adminGlobal/DrawerHeader'
import {
  Container,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Typography } from '@mui/material'

const Dashboard = () => {
  return (
    <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
   <DrawerHeader/>

   <Typography variant='h1' sx={{justifyContent:"center"}} color={"blueviolet"}>Dashboard</Typography>
   </Grid>
  )
}

export default Dashboard