// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import Alert from "@mui/material/Alert";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// export const AddCategories = () => {
//   const [categoriesName, setCategoriesName] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [responseMessageError, setResponseMessageError] = useState("");
//   const [open, setOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [response, setResponse] = useState("");
//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? "dark" : "light",
//     },
//   });

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/getcategories`)
//       .then((response) => {
//         const formattedCategories = response.data
//           .reverse()
//           .map((category, index) => {
//             return {
//               id: category.id || index + 1,
//               name: category.categoriesName || "N/A",
//             };
//           });

//         setCategories(formattedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the categories!", error);
//       });
//   }, []);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setCategoriesName(""); // Clear the input field when the dialog is closed
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_URL}/api/addcategories`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ categoriesName }),
//         }
//       );

//       const data = await response.json();

//       if (response.ok) {
//         setResponseMessage(data.message);
//         handleClose(); // Close the dialog after successful submission
//       } else {
//         setResponseMessage(data.message || "Error creating categories");
//       }
//     } catch (error) {
//       setResponseMessageError("Error: Unable to create categories");
//     }
//   };

//   const handleThemeChange = () => {
//     setDarkMode(!darkMode);
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteCategories/${id}`
//       );
//       setResponse("Subscriber deleted successfully!");

//       setCategories(categories.filter((category) => category._id !== id));

//       setTimeout(() => {
//         setResponse("");
//       }, 2000);
//     } catch (error) {
//       console.error("Error deleting subscriber:", error);
//       setResponse("Error deleting subscriber!");

//       setTimeout(() => {
//         setResponse("");
//       }, 4000);
//     }
//   };

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//       <DrawerHeader />

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         sx={{ display: "flex", flexDirection: "column" }}
//       >
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Grid
//             sx={{
//               p: 3,
//               background: darkMode
//                 ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//                 : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//               borderRadius: 2,
//               position: "relative",
//             }}
//           >
//             <Typography
//               variant="h5"
//               component="div"
//               sx={{ mb: 2, color: "white" }}
//             >
//               Categories Information
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleClickOpen}
//               sx={{
//                 position: "absolute",
//                 top: { xs: 8, sm: 16 }, // Adjust the top position for mobile
//                 right: { xs: 8, sm: 16 }, // Adjust the right position for mobile
//                 padding: { xs: "4px 8px", sm: "6px 16px" }, // Adjust padding
//                 fontSize: { xs: "12px", sm: "14px" }, // Smaller font size on mobile
//                 borderRadius: "8px",
//                 textTransform: "none",
//                 minWidth: { xs: "80px", sm: "100px" }, // Minimum width adjustment
//                 border: "2px solid black",
//               }}
//             >
//               Add Categories
//             </Button>

//             {/* Simple HTML Table for displaying categories */}
//             <Box
//               sx={{
//                 mt: 3,
//                 width: "100%",
//                 backgroundColor: "white",
//                 borderRadius: 2,
//                 p: 2,
//                 overflowX: "auto",
//               }}
//             >
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#f4f4f4" }}>
//                     <th style={{ padding: "8px", border: "1px solid #ddd" }}>
//                       ID
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd" }}>
//                       Categories Name
//                     </th>
//                     <th style={{ border: "1px solid #ddd", padding: "6px" }}>
//                     ACTIONS
//                   </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {categories.map((categories) => (
//                     <tr key={categories.id} style={{ backgroundColor: "#fff" }}>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {categories.id}
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {categories.name}
//                       </td>
//                      <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                       <Button
//                         variant="contained"
//                         color="error"
//                         onClick={() => handleDelete(categories._id)}
//                       >
//                         Delete
//                       </Button>
//                     </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Box>
//           </Grid>
//         </ThemeProvider>
//       </Grid>

//       {responseMessage && (
//         <Alert severity="success" color="success">
//           <Typography variant="body1" color="secondary" mt={2}>
//             {responseMessage}
//           </Typography>
//         </Alert>
//       )}

//       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//         <DialogTitle
//           sx={{
//             textAlign: "center",
//             backgroundColor: darkMode ? "#333" : "#FFD700",
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{ color: darkMode ? "#FFD700" : "#333" }}
//           >
//             Add Category Details
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} padding={"20px"}>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Enter Category name"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//                 sx={{
//                   input: { color: "#333", borderRadius: "4px" },
//                   "& .MuiOutlinedInput-root": {
//                     "& fieldset": { borderColor: "#FFD700" },
//                   },
//                 }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
//           <Button
//             onClick={handleSubmit}
//             variant="contained"
//             sx={{
//               backgroundColor: "#FFD700",
//               color: "#212121",
//               "&:hover": { backgroundColor: "#FFC700" },
//               textTransform: "none",
//               borderRadius: "8px",
//               minWidth: "100px",
//             }}
//           >
//             Submit
//           </Button>
//           <Button
//             onClick={handleClose}
//             variant="outlined"
//             sx={{
//               borderColor: "#FFD700",
//               color: "#212121",
//               "&:hover": { borderColor: "#FFC700", color: "#212121" },
//               textTransform: "none",
//               borderRadius: "8px",
//               minWidth: "100px",
//             }}
//           >
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Grid>
//   );
// };

// export default AddCategories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
//   Snackbar,
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import Alert from "@mui/material/Alert";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// export const AddCategories = () => {
//   const [categoriesName, setCategoriesName] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [responseMessageError, setResponseMessageError] = useState("");
//   const [open, setOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [response, setResponse] = useState("");
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' or 'error'

//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? "dark" : "light",
//     },
//   });

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/getcategories`)
//       .then((response) => {
//         // const formattedCategories = response.data
//         //   .reverse()
//         //   .map((category, index) => {
//         //     return {
//         //       id: category.id || index + 1,
//         //       name: category.categoriesName || "N/A",
//         //     };
//         //   });
//         console.log(response.data);
//         const formattedCategories = response.data.reverse();

//         setCategories(formattedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the categories!", error);
//       });
//   }, []);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setCategoriesName(""); // Clear the input field when the dialog is closed
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_URL}/api/addcategories`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ categoriesName }),
//         }
//       );

//       const data = await response.json();

//       if (response.ok) {
//         setResponseMessage(data.message);
//         handleClose();
//     setSnackbarOpen(true); // Show Snackbar on success
//     // Close the dialog after successful submission
//       } else {
//         setResponseMessage(data.message || "Error creating category");
//     setSnackbarOpen(true); // Show Snackbar on success

//       }
//     } catch (error) {
//       setResponseMessageError("Error: Unable to create category");
//     setSnackbarOpen(true); // Show Snackbar on success

//     }
//   };

//   const handleThemeChange = () => {
//     setDarkMode(!darkMode);
//   };

//   const handleDelete = async (id) => {
//     console.log("Attempting to delete category with id:", id); // Debugging statement

//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteCategories/${id}`
//       );
//       console.log("Delete response:", response); // Debugging statement

//       if (response.status === 200) {
//         // Check if deletion was successful
//         setResponse("Category deleted successfully!");
//         setCategories(categories.filter((category) => category._id !== id));
//     setSnackbarOpen(true); // Show Snackbar on success

//       } else {
//         setResponse("Error deleting category!");
//     setSnackbarOpen(true); // Show Snackbar on success

//       }

//       setTimeout(() => {
//         setResponse("");
//       }, 2000);
//     } catch (error) {
//       console.error("Error deleting category:", error);
//       setResponse("Error deleting category!");
//     setSnackbarOpen(true); // Show Snackbar on success

//       setTimeout(() => {
//         setResponse("");
//       }, 4000);
//     }
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//     };

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//       <DrawerHeader />

//       <Snackbar
//   open={snackbarOpen}
//   autoHideDuration={6000} // Auto hide after 6 seconds
//   onClose={handleSnackbarClose}
//   anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of Snackbar
// >
//   <Alert
//     onClose={handleSnackbarClose}
//     severity={snackbarSeverity} // Use dynamic severity
//     sx={{
//       width: '100%',
//       backgroundColor: snackbarSeverity === 'success' ? '#90EE90' : 'red', // #90EE90 for success, red for error
//       color: 'white', // Ensure text is readable
//     }}
//   >
//     {responseMessage}
//   </Alert>
// </Snackbar>

//       {responseMessageError && (
//         <Alert severity="error" color="error">
//           <Typography variant="body1" color="secondary" mt={2}>
//             {responseMessageError}
//           </Typography>
//         </Alert>
//       )}
//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         sx={{ display: "flex", flexDirection: "column" }}
//       >
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Grid
//             sx={{
//               p: 3,
//               background: darkMode
//                 ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//                 : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//               borderRadius: 2,
//               position: "relative",
//             }}
//           >
//             <Typography
//               variant="h5"
//               component="div"
//               sx={{ mb: 2, color: "white" }}
//             >
//               Categories Information
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleClickOpen}
//               sx={{
//                 position: "absolute",
//                 top: { xs: 8, sm: 16 }, // Adjust the top position for mobile
//                 right: { xs: 8, sm: 16 }, // Adjust the right position for mobile
//                 padding: { xs: "4px 8px", sm: "6px 16px" }, // Adjust padding
//                 fontSize: { xs: "12px", sm: "14px" }, // Smaller font size on mobile
//                 borderRadius: "8px",
//                 textTransform: "none",
//                 minWidth: { xs: "80px", sm: "100px" }, // Minimum width adjustment
//                 border: "2px solid black",
//               }}
//             >
//               Add Categories
//             </Button>

//             {/* Simple HTML Table for displaying categories */}
//             <Box
//               sx={{
//                 mt: 3,
//                 width: "100%",
//                 backgroundColor: "white",
//                 borderRadius: 2,
//                 p: 2,
//                 overflowX: "auto",
//               }}
//             >
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#f4f4f4" }}>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ID
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       Categories Name
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ACTIONS
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {categories.map((category, index) => (
//                     <tr key={category._id} style={{ backgroundColor: "#fff" }}>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {index + 1}
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {category.categoriesName}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           onClick={() => handleDelete(category._id)} // Use category.id here
//                         >
//                           Delete
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Box>
//           </Grid>
//         </ThemeProvider>
//       </Grid>

//       {responseMessage && (
//         <Alert severity="success" color="success">
//           <Typography variant="body1" color="secondary" mt={2}>
//             {responseMessage}
//           </Typography>
//         </Alert>
//       )}

//       {responseMessageError && (
//         <Alert severity="error" color="error">
//           <Typography variant="body1" color="secondary" mt={2}>
//             {responseMessageError}
//           </Typography>
//         </Alert>
//       )}

//       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//         <DialogTitle
//           sx={{
//             textAlign: "center",
//             backgroundColor: darkMode ? "#333" : "#FFD700",
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{ color: darkMode ? "#FFD700" : "#333" }}
//           >
//             Add Category Details
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} padding={"20px"}>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Enter Category name"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//                 sx={{
//                   input: { color: "#333", borderRadius: "4px" },
//                   "& .MuiOutlinedInput-root": {
//                     "& fieldset": { borderColor: "#FFD700" },
//                   },
//                 }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
//           <Button
//             onClick={handleSubmit}
//             variant="contained"
//             sx={{
//               backgroundColor: "#FFD700",
//               color: "#212121",
//               "&:hover": { backgroundColor: "#FFC700" },
//               textTransform: "none",
//               borderRadius: "8px",
//               minWidth: "100px",
//             }}
//           >
//             Submit
//           </Button>
//           <Button
//             onClick={handleClose}
//             variant="outlined"
//             sx={{
//               borderColor: "#FFD700",
//               color: "#212121",
//               "&:hover": { borderColor: "#FFC700", color: "#212121" },
//               textTransform: "none",
//               borderRadius: "8px",
//               minWidth: "100px",
//             }}
//           >
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Grid>
//   );
// };

// export default AddCategories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
//   Snackbar,
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import Alert from "@mui/material/Alert";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// export const AddCategories = () => {
//   const [categoriesName, setCategoriesName] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [responseMessageError, setResponseMessageError] = useState("");
//   const [open, setOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' or 'error'

//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? "dark" : "light",
//     },
//   });

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/getcategories`)
//       .then((response) => {
//         const formattedCategories = response.data.reverse();
//         setCategories(formattedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the categories!", error);
//       });
//   }, []);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setCategoriesName(""); // Clear the input field when the dialog is closed
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_URL}/api/addcategories`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ categoriesName }),
//         }
//       );

//       const data = await response.json();

//       if (response.ok) {
//         setResponseMessage(data.message);
//         setSnackbarSeverity("success");
//       } else {
//         setResponseMessage(data.message || "Error creating category");
//         setSnackbarSeverity("error");
//       }
//       handleClose();
//       setSnackbarOpen(true); // Show Snackbar on success or error
//     } catch (error) {
//       setResponseMessage("Error: Unable to create category");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true); // Show Snackbar on error
//     }
//   };

//   const handleThemeChange = () => {
//     setDarkMode(!darkMode);
//   };

//   const handleDelete = async (id) => {
//     console.log("Attempting to delete category with id:", id); // Debugging statement

//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteCategories/${id}`
//       );
//       console.log("Delete response:", response); // Debugging statement

//       if (response.status === 200) {
//         setResponseMessage("Category deleted successfully!");
//         setSnackbarSeverity("success");
//         setCategories(categories.filter((category) => category._id !== id));
//       } else {
//         setResponseMessage("Error deleting category!");
//         setSnackbarSeverity("error");
//       }

//       setSnackbarOpen(true); // Show Snackbar on success or error

//       setTimeout(() => {
//         setResponseMessage("");
//       }, 2000);
//     } catch (error) {
//       console.error("Error deleting category:", error);
//       setResponseMessage("Error deleting category!");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true); // Show Snackbar on error

//       setTimeout(() => {
//         setResponseMessage("");
//       }, 4000);
//     }
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     // <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//     <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>

//       <DrawerHeader />

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000} // Auto hide after 6 seconds
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of Snackbar
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity} // Use dynamic severity
//           sx={{
//             width: "100%",
//             backgroundColor:
//               snackbarSeverity === "success" ? "#90EE90" : "#f44336", // Light green for success, red for error
//             color: "white", // Ensure text is readable
//           }}
//         >
//           {responseMessage}
//         </Alert>
//       </Snackbar>

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         sx={{ display: "flex", flexDirection: "column" }}
//       >
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Grid
//             sx={{
//               p: 3,
//               background: darkMode
//                 ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//                 : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//               borderRadius: 2,
//               position: "relative",
//             }}
//           >
//             <Typography
//               variant="h5"
//               component="div"
//               sx={{ mb: 2, color: "white" }}
//             >
//               Categories Information
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleClickOpen}
//               sx={{
//                 position: "absolute",
//                 top: { xs: 8, sm: 16 }, // Adjust the top position for mobile
//                 right: { xs: 8, sm: 16 }, // Adjust the right position for mobile
//                 padding: { xs: "4px 8px", sm: "6px 16px" }, // Adjust padding
//                 fontSize: { xs: "12px", sm: "14px" }, // Smaller font size on mobile
//                 borderRadius: "8px",
//                 textTransform: "none",
//                 minWidth: { xs: "80px", sm: "100px" }, // Minimum width adjustment
//                 border: "2px solid black",
//               }}
//             >
//               Add Categories
//             </Button>

//             {/* Simple HTML Table for displaying categories */}
//             <Box
//               sx={{
//                 mt: 3,
//                 width: "100%",
//                 backgroundColor: "white",
//                 borderRadius: 2,
//                 p: 2,
//                 overflowX: "auto",
//               }}
//             >
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#f4f4f4" }}>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ID
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       Categories Name
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ACTIONS
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {categories.map((category, index) => (
//                     <tr key={category._id} style={{ backgroundColor: "#fff" }}>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {index + 1}
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {category.categoriesName}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           onClick={() => handleDelete(category._id)} // Use category._id here
//                         >
//                           Delete
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Box>
//           </Grid>
//         </ThemeProvider>
//       </Grid>

//       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//         <DialogTitle
//           sx={{
//             textAlign: "center",
//             backgroundColor: darkMode ? "#333" : "#FFD700",
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{ color: darkMode ? "#FFD700" : "#333" }}
//           >
//             Add Category Details
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} mt={5}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 id="categoriesName"
//                 label="Category Name"
//                 variant="outlined"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//                 required
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleSubmit}
//             color="primary"
//             sx={{
//               backgroundColor: "#4CAF50", // Green color
//               "&:hover": {
//                 backgroundColor: "#45a049", // Darker green on hover
//               },
//               color: "white", // Ensure the text is readable
//             }}
//           >
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>
//     {/* </Grid> */}
//     </Box>
//   );
// };
// export default AddCategories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// const AddCategories = () => {
//   const [categoriesName, setCategoriesName] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [open, setOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? "dark" : "light",
//     },
//   });

//   // Fetch categories on component mount
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_URL}/api/getcategories`
//       );
//       setCategories(response.data.reverse());
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   // Open the dialog
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   // Close the dialog and reset input
//   const handleClose = () => {
//     setOpen(false);
//     setCategoriesName("");
//   };

//   // Submit a new category with optimistic update
//   const handleSubmit = async () => {
//     const newCategory = { categoriesName };

//     // Optimistically update UI by adding the category to the state immediately
//     setCategories((prevCategories) => [newCategory, ...prevCategories]);

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/addcategories`,
//         { categoriesName }
//       );

//       // Update the optimistic state with the response data
//       setCategories((prevCategories) => {
//         // Remove the placeholder and replace with the actual saved category from the backend
//         const updatedCategories = [...prevCategories];
//         updatedCategories[0] = response.data.category;
//         return updatedCategories;
//       });

//       setResponseMessage("Category added successfully!");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error creating category:", error);
//       setResponseMessage("Error: Unable to create category");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);

//       // Rollback the optimistic update on error
//       setCategories((prevCategories) =>
//         prevCategories.filter((category) => category !== newCategory)
//       );
//     } finally {
//       handleClose();
//     }
//   };

//   // Toggle dark mode
//   const handleThemeChange = () => {
//     setDarkMode(!darkMode);
//   };

//   // Handle category deletion
//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteCategories/${id}`
//       );

//       if (response.status === 200) {
//         setCategories(categories.filter((category) => category._id !== id));
//         setResponseMessage("Category deleted successfully!");
//         setSnackbarSeverity("success");
//       } else {
//         setResponseMessage("Error deleting category!");
//         setSnackbarSeverity("error");
//       }
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error deleting category:", error);
//       setResponseMessage("Error deleting category!");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     }
//   };

//   // Close the snackbar
//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>
//       <DrawerHeader />

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{
//             width: "100%",
//             backgroundColor:
//               snackbarSeverity === "success" ? "#90EE90" : "#f44336",
//             color: "white",
//           }}
//         >
//           {responseMessage}
//         </Alert>
//       </Snackbar>

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         sx={{ display: "flex", flexDirection: "column" }}
//       >
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Grid
//             sx={{
//               p: 3,
//               background: darkMode
//                 ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//                 : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//               borderRadius: 2,
//               position: "relative",
//             }}
//           >
//             <Typography
//               variant="h5"
//               component="div"
//               sx={{ mb: 2, color: "white" }}
//             >
//               Categories Information
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleClickOpen}
//               sx={{
//                 position: "absolute",
//                 top: { xs: 8, sm: 16 },
//                 right: { xs: 8, sm: 16 },
//                 padding: { xs: "4px 8px", sm: "6px 16px" },
//                 fontSize: { xs: "12px", sm: "14px" },
//                 borderRadius: "8px",
//                 textTransform: "none",
//                 minWidth: { xs: "80px", sm: "100px" },
//                 border: "2px solid black",
//               }}
//             >
//               Add Categories
//             </Button>

//             <Box
//               sx={{
//                 mt: 3,
//                 width: "100%",
//                 backgroundColor: "white",
//                 borderRadius: 2,
//                 p: 2,
//                 overflowX: "auto",
//               }}
//             >
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#f4f4f4" }}>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ID
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       Categories Name
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ACTIONS
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {categories.map((category, index) => (
//                     <tr key={category._id || index} style={{ backgroundColor: "#fff" }}>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {index + 1}
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         {category.categoriesName}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           onClick={() => handleDelete(category._id)}
//                         >
//                           Delete
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Box>
//           </Grid>
//         </ThemeProvider>
//       </Grid>

//       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//         <DialogTitle
//           sx={{
//             textAlign: "center",
//             backgroundColor: darkMode ? "#333" : "#FFD700",
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{ color: darkMode ? "#FFD700" : "#333" }}
//           >
//             Add Category Details
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} mt={5}>
//             <Grid item xs={12}>
//               <TextField
//                 fullWidth
//                 id="categoriesName"
//                 label="Category Name"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//                 inputProps={{
//                   style: {
//                     color: darkMode ? "white" : "black",
//                     borderColor: darkMode ? "white" : "black",
//                   },
//                 }}
//                 sx={{
//                   "& .MuiOutlinedInput-root": {
//                     "& fieldset": {
//                       borderColor: darkMode ? "#FFD700" : "black",
//                     },
//                     "&:hover fieldset": {
//                       borderColor: darkMode ? "#FFD700" : "#333",
//                     },
//                     "&.Mui-focused fieldset": {
//                       borderColor: darkMode ? "#FFD700" : "black",
//                     },
//                   },
//                 }}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={handleClose}
//             color="primary"
//             sx={{
//               backgroundColor: "darkred",
//               color: "white",
//               "&:hover": { backgroundColor: "red" },
//             }}
//           >
//             Cancel
//           </Button>
//           <Button
//             onClick={handleSubmit}
//             color="primary"
//             sx={{
//               backgroundColor: "darkgreen",
//               color: "white",
//               "&:hover": { backgroundColor: "green" },
//             }}
//           >
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default AddCategories;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";

// const AddCategories = () => {
//   const [categoriesName, setCategoriesName] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [open, setOpen] = useState(false);
//   const [editOpen, setEditOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [editingCategory, setEditingCategory] = useState(null);

//   const theme = createTheme({
//     palette: {
//       mode: darkMode ? "dark" : "light",
//     },
//   });

//   // Fetch categories on component mount
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_URL}/api/getcategories`
//       );
//       setCategories(response.data.reverse());
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   // Open the dialog for adding a category
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   // Close the dialog for adding a category and reset input
//   const handleClose = () => {
//     setOpen(false);
//     setCategoriesName("");
//   };

//   // Open the edit dialog
//   const handleEditClickOpen = (category) => {
//     setEditingCategory(category);
//     setCategoriesName(category.categoriesName);
//     setEditOpen(true);
//   };

//   // Close the edit dialog and reset input
//   const handleEditClose = () => {
//     setEditOpen(false);
//     setCategoriesName("");
//     setEditingCategory(null);
//   };

//   // Submit a new category with optimistic update
//   const handleSubmit = async () => {
//     const newCategory = { categoriesName };

//     setCategories((prevCategories) => [newCategory, ...prevCategories]);

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/addcategories`,
//         { categoriesName }
//       );

//       setCategories((prevCategories) => {
//         const updatedCategories = [...prevCategories];
//         updatedCategories[0] = response.data.category;
//         return updatedCategories;
//       });

//       setResponseMessage("Category added successfully!");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error creating category:", error);
//       setResponseMessage("Error: Unable to create category");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);

//       setCategories((prevCategories) =>
//         prevCategories.filter((category) => category !== newCategory)
//       );
//     } finally {
//       handleClose();
//     }
//   };

//   const handleUpdate = async () => {
//     if (!editingCategory) return;

//     const updatedCategory = { categoriesName };

//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_URL}/api/updatecategories/${editingCategory._id}`,
//         updatedCategory
//       );

//       setCategories((prevCategories) =>
//         prevCategories.map((category) =>
//           category._id === response.data.category._id
//             ? response.data.category
//             : category
//         )
//       );

//       setResponseMessage("Category updated successfully!");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error updating category:", error);
//       setResponseMessage("Error: Unable to update category");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     } finally {
//       handleEditClose();
//     }
//   };

//   // Handle category deletion
//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteCategories/${id}`
//       );

//       if (response.status === 200) {
//         setCategories(categories.filter((category) => category._id !== id));
//         setResponseMessage("Category deleted successfully!");
//         setSnackbarSeverity("success");
//       } else {
//         setResponseMessage("Error deleting category!");
//         setSnackbarSeverity("error");
//       }
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error deleting category:", error);
//       setResponseMessage("Error deleting category!");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     }
//   };

//   // Close the snackbar
//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>
//       <DrawerHeader />

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{
//             width: "100%",
//             backgroundColor:
//               snackbarSeverity === "success" ? "#90EE90" : "#f44336",
//             color: "white",
//           }}
//         >
//           {responseMessage}
//         </Alert>
//       </Snackbar>

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={10}
//         sx={{ display: "flex", flexDirection: "column" }}
//       >
//         <ThemeProvider theme={theme}>
//           <CssBaseline />
//           <Grid
//             sx={{
//               p: 3,
//               background: darkMode
//                 ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//                 : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//               borderRadius: 2,
//               position: "relative",
//             }}
//           >
//             <Typography
//               variant="h5"
//               component="div"
//               sx={{ mb: 2, color: "white" }}
//             >
//               Categories Information
//             </Typography>
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleClickOpen}
//               sx={{
//                 position: "absolute",
//                 top: { xs: 8, sm: 16 },
//                 right: { xs: 8, sm: 16 },
//                 padding: { xs: "4px 8px", sm: "6px 16px" },
//                 fontSize: { xs: "12px", sm: "14px" },
//                 borderRadius: "8px",
//                 textTransform: "none",
//                 minWidth: { xs: "80px", sm: "100px" },
//                 border: "2px solid black",
//               }}
//             >
//               Add Categories
//             </Button>

//             <Box
//               sx={{
//                 mt: 3,
//                 width: "100%",
//                 backgroundColor: "white",
//                 borderRadius: 2,
//                 p: 2,
//                 overflowX: "auto",
//               }}
//             >
//               <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                 <thead>
//                   <tr style={{ backgroundColor: "#f4f4f4" }}>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       ID
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       Categories Name
//                     </th>
//                     <th
//                       style={{
//                         padding: "8px",
//                         border: "1px solid #ddd",
//                         backgroundColor: "#333",
//                         color: "white",
//                       }}
//                     >
//                       Edit
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {categories.map((category, index) => (
//                     <tr
//                       key={category._id || index}
//                       style={{ backgroundColor: "#fff" }}
//                     >
//                       <td
//                         style={{
//                           padding: "8px",
//                           border: "1px solid #ddd",
//                         }}
//                       >
//                         {index + 1}
//                       </td>
//                       <td
//                         style={{
//                           padding: "8px",
//                           border: "1px solid #ddd",
//                         }}
//                       >
//                         {category.categoriesName}
//                       </td>
//                       <td
//                         style={{
//                           padding: "8px",
//                           border: "1px solid #ddd",
//                           textAlign: "center",
//                         }}
//                       >
//                         <Button
//                           variant="contained"
//                           color="info"
//                           onClick={() => handleEditClickOpen(category)}
//                           sx={{
//                             mr: 1,
//                             backgroundColor: "blue",
//                             "&:hover": { backgroundColor: "darkblue" },
//                             color: "white",
//                           }}
//                         >
//                           Edit
//                         </Button>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           onClick={() => handleDelete(category._id)}
//                           sx={{
//                             ml: 1,
//                             backgroundColor: "red",
//                             "&:hover": { backgroundColor: "darkred" },
//                             color: "white",
//                           }}
//                         >
//                           Delete
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Box>
//           </Grid>

//           {/* Add Category Dialog */}
//           <Dialog open={open} onClose={handleClose}>
//             <DialogTitle>Add Category</DialogTitle>
//             <DialogContent>
//               <TextField
//                 autoFocus
//                 margin="dense"
//                 id="categoriesName"
//                 label="Category Name"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//               />
//             </DialogContent>
//             <DialogActions>
//               <Button
//                 onClick={handleClose}
//                 color="secondary"
//                 sx={{
//                   backgroundColor: "red",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "darkred",
//                   },
//                   padding: "6px 16px",
//                   fontSize: "14px",
//                   borderRadius: "8px",
//                 }}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 onClick={handleSubmit}
//                 color="primary"
//                 sx={{
//                   backgroundColor: "green",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "darkgreen",
//                   },
//                   padding: "6px 16px",
//                   fontSize: "14px",
//                   borderRadius: "8px",
//                 }}
//               >
//                 Add
//               </Button>
//             </DialogActions>
//           </Dialog>

//           {/* Edit Category Dialog */}
//           <Dialog open={editOpen} onClose={handleEditClose}>
//             <DialogTitle>Edit Category</DialogTitle>
//             <DialogContent>
//               <TextField
//                 autoFocus
//                 margin="dense"
//                 id="categoriesName"
//                 label="Category Name"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 value={categoriesName}
//                 onChange={(e) => setCategoriesName(e.target.value)}
//               />
//             </DialogContent>
//             <DialogActions>
//               <Button
//                 onClick={handleEditClose}
//                 color="secondary"
//                 sx={{
//                   backgroundColor: "red",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "darkred",
//                   },
//                   padding: "6px 16px",
//                   fontSize: "14px",
//                   borderRadius: "8px",
//                 }}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 onClick={handleUpdate}
//                 color="primary"
//                 sx={{
//                   backgroundColor: "green",
//                   color: "white",
//                   "&:hover": {
//                     backgroundColor: "darkgreen",
//                   },
//                   padding: "6px 16px",
//                   fontSize: "14px",
//                   borderRadius: "8px",
//                 }}
//               >
//                 Update
//               </Button>
//             </DialogActions>
//           </Dialog>
//         </ThemeProvider>
//       </Grid>
//     </Box>
//   );
// };

// export default AddCategories;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const AddCategories = () => {
  const [categoriesName, setCategoriesName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [editingCategory, setEditingCategory] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  // Fetch categories on component mount
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/getcategories`
      );
      setCategories(response.data.reverse());
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Open the dialog for adding a category
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close the dialog for adding a category and reset input
  const handleClose = () => {
    setOpen(false);
    setCategoriesName("");
  };

  // Open the edit dialog
  const handleEditClickOpen = (category) => {
    setEditingCategory(category);
    setCategoriesName(category.categoriesName);
    setEditOpen(true);
  };

  // Close the edit dialog and reset input
  const handleEditClose = () => {
    setEditOpen(false);
    setCategoriesName("");
    setEditingCategory(null);
  };

  // Open the delete confirmation dialog
  const handleDeleteClickOpen = (category) => {
    setCategoryToDelete(category);
    setConfirmDeleteOpen(true);
  };

  // Close the delete confirmation dialog
  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setCategoryToDelete(null);
  };

  // Submit a new category with optimistic update
  const handleSubmit = async () => {
    const newCategory = { categoriesName };

    setCategories((prevCategories) => [newCategory, ...prevCategories]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/addcategories`,
        { categoriesName }
      );

      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        updatedCategories[0] = response.data.category;
        return updatedCategories;
      });

      setResponseMessage("Category added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error creating category:", error);
      setResponseMessage("Error: Unable to create category");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);

      setCategories((prevCategories) =>
        prevCategories.filter((category) => category !== newCategory)
      );
    } finally {
      handleClose();
    }
  };

  const handleUpdate = async () => {
    if (!editingCategory) return;

    const updatedCategory = { categoriesName };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/api/updatecategories/${editingCategory._id}`,
        updatedCategory
      );

      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === response.data.category._id
            ? response.data.category
            : category
        )
      );

      setResponseMessage("Category updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating category:", error);
      setResponseMessage("Error: Unable to update category");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      handleEditClose();
    }
  };

  // Handle category deletion
  const handleDelete = async () => {
    if (!categoryToDelete) return;

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/api/deleteCategories/${categoryToDelete._id}`
      );

      if (response.status === 200) {
        setCategories(
          categories.filter((category) => category._id !== categoryToDelete._id)
        );
        setResponseMessage("Category deleted successfully!");
        setSnackbarSeverity("success");
      } else {
        setResponseMessage("Error deleting category!");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting category:", error);
      setResponseMessage("Error deleting category!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      handleConfirmDeleteClose();
    }
  };

  // Close the snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={6} display="flex" justifyContent={"center"} direction="row" mt={12}>
      <DrawerHeader />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            backgroundColor:
              snackbarSeverity === "success" ? "#90EE90" : "#f44336",
            color: "white",
          }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      <Grid
        container
        xs={12}
        md={10}
        lg={10}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Grid
            sx={{
              p: 3,
              background: darkMode
                ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
                : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
              borderRadius: 2,
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ mb: 2, color: "white" }}
            >
              Categories Information
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                position: "absolute",
                top: { xs: 8, sm: 16 },
                right: { xs: 8, sm: 16 },
                padding: { xs: "4px 8px", sm: "6px 16px" },
                fontSize: { xs: "12px", sm: "14px" },
                borderRadius: "8px",
                textTransform: "none",
                minWidth: { xs: "80px", sm: "100px" },
                border: "2px solid black",
              }}
            >
              Add Categories
            </Button>

            <Box
              sx={{
                mt: 3,
                width: "100%",
                backgroundColor: "white",
                borderRadius: 2,
                p: 2,
                overflowX: "auto",
              }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ backgroundColor: "#f4f4f4" }}>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Categories Name
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Edit
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index) => (
                    <tr
                      key={category._id || index}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid #ddd",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid #ddd",
                        }}
                      >
                        {category.categoriesName}
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEditClickOpen(category)}
                        >
                          Edit
                        </Button>
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            ml: 1,
                            backgroundColor: "red",
                            "&:hover": { backgroundColor: "darkred" },
                            color: "white",
                          }}
                          onClick={() => handleDeleteClickOpen(category)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
        </ThemeProvider>
      </Grid>

      {/* Add Category Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={categoriesName}
            onChange={(e) => setCategoriesName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              ml: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{
              ml: 1,
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Category Dialog */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            value={categoriesName}
            onChange={(e) => setCategoriesName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditClose}
            sx={{
              ml: 1,
              backgroundColor: "red",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdate}
            sx={{
              ml: 1,
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkred" },
              color: "white",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Delete Dialog */}
      <Dialog open={confirmDeleteOpen} onClose={handleConfirmDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddCategories;
