import { Button } from "@mui/material";
import React from "react";

const Test = () => {
  const xyz = () => {
    // for (let i=1; i<=100; i++){
    //   if(i%2 === 0){
    //     console.log("even number" , i);
    //   }
    //   else if(i){
    //     console.log("even" ,i);
    //   }else{
    //     console.log("prime" ,i)

    //   }

    // }

    //   const array = [10 , 20 , 30 , 40 , 50 , 60];

    //   console.log(array[3]);

    // const evenarray = [];
    // const oddarray = [];
    // for (let i = 1; i <= 100; i++) {
    //   if (i % 2 === 0) {
    //    evenarray[i] = i;
    //   } else  {
    //     oddarray[i] = i;
    //   }
    // console.log([array[i]])
    // }
    // console.log(evenarray);
    // console.log(oddarray);

    // const array = [10, 20, 30, 40, 50, 60];
    // const a = 20;
    // for (let i = 0; i <= 5; i++) {
    //   // if(a<=[array.length[5]]){

    //   if (array[i] === a) {
    //     console.log("yes");
    //   } else {
    //     console.log("no");
    //   }
    // }

    const array = [10, 20, 30, 40, 50, 60];
    const abc = [];

    // xyz[0] = array[0]
    // xyz[1] = array[1]
    // xyz[2] = array[2]
    // xyz[3] = array[3]
    // xyz[4] = array[4]
    // xyz[5] = array[5]

    // for(let i=0;i<=5;i++){
    // abc[0] = array[0]
    //   abc[1] = array[1]
    //   abc[2] = array[2]
    //   abc[3] = array[3]
    //   abc[4] = array[4]
    //   abc[5] = array[5]
    // }

    // let max = array[0];
    // for (let i = 0; i <= 5; i++) {
    //   if (array[i] > max) {
    //     max = array[i];
    //   }
    // }

    // console.log(max);

    // let min = array[0];
    // for (let i = 0; i <= 5; i++) {
    //   if (array[i] < min) {
    //     min = array[i];
    //   }
    // }
    // console.log("minimum value is", min);

    array.splice(2, 1);
    array.splice(4, 3, 'a', 'b');

    console.log(array);
  };
  // console.log(i)

  return (
    <div>
      <Button onClick={xyz}>Even Numbers</Button>
    </div>
  );
};

export default Test;
