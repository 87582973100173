import React from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  CardMedia,
  Avatar,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import RedditIcon from "@mui/icons-material/Reddit";
import NavBar from "../global/header";
import Grid from "@mui/material/Grid";
import Footer from "../global/footer";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import tv from "../main/img/tv.gif";
import CardContent from "@mui/material/CardContent";
import seoImg from "../main/img/seo.jpg"; // Add this image
import DigitalImg from "../main/img/Digital.png";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";



function Banner1(article) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userArticle = article.article;

  return (
    <Grid
      container
      xs={12}
      md={12}
      lg={12}
      sx={{
        // border: "2px solid black",
        padding: isMobile ? 3 : 0,
      }}
    >
      
      <Grid item xs={12} md={11} lg={12}>
        {/* <Typography
          variant="h5" // Changed variant to h6 as h9 doesn't exist in MUI
          component="h2"
          sx={{
            fontWeight: "bold",
            mt: 0,
            mb: 2,
            textAlign: "left",
          }}
        >
          {userArticle.shortdescription}
        </Typography> */}

        <Grid item xs={12} md={11} lg={11}>
          <div
            style={{ textAlign: "left", overflowX: "hidden"}}
            dangerouslySetInnerHTML={{ __html: userArticle.body }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function NewsletterSignUp() {
  return (
    <Grid sx={{ textAlign: "left", mt: 3 }}>
      <Grid container xs={12} md={11} lg={11} spacing={1} justifyContent="left">
        <Grid item xs={12} md={10} lg={10}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Sign up for the Digiday Daily Newsletter
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
            Get the latest news on media, marketing and the future of TV,
            delivered each weekday morning
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <TextField
            fullWidth
            label="Business email"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: {
                borderBottom: "1px solid black",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={8}>
          <TextField
            fullWidth
            label="Job title"
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: {
                borderBottom: "1px solid black",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "black",
              color: "white",
              mt: { xs: 2, sm: 0 },
              px: 3,
              py: 1,
              textTransform: "none",
            }}
          >
            SIGN UP
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}



function ImageSection3() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  console.log(slug);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        // const articlesArray = response.data;
        const articlesArray = response.data.slice(0, 3);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      paddingTop={2}
    >
      {article.map((article, index) => (
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          key={index}
          sx={{ position: "relative",cursor:"pointer" }}
          onClick={() => handleClick(article.slug)}

        >
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              component="img"
              image={article.imagelink}
              alt={article.headertext}
              sx={{
                height: "auto",
                // width: { xs: "30%", md: 180, lg: 280, xl: 330 },

                objectFit: "contain",
              }}
            />
            <CardContent
              sx={{
                flexGrow: 1,
                pt: { xs: 0, md: 2 },
                pl: { xs: 2, md: 0 },
              }}
            >
              <Grid item xs={12} md={12} lg={12} key={index}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      fontSize: "0.75rem",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                                  {article.headertext}

                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="body1"
                    component="div"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 1,
                      textAlign: "left",
                    }}
                  >
                                        {article.shortdescription}

                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    gutterBottom
                    sx={{ display: "flex", justifyContent: "left" }}
                  >
                    {article.date}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      display: { xs: "none", md: "block" }, // Hide text on mobile view
                    }}
                  >
                    {article.metadescription}
                  </Typography> */}
                 <Typography
                  variant="body2"
                  sx={{
                    display: "flex", // Flex display to keep icon and text on the same line
                    alignItems: "center", // Center the icon and text vertically
                    textAlign: "left",
                    mt: 5,
                    alignSelf: "flex-start", // Align to the start to position it on the left
                  }}
                >
                  <RemoveRedEyeIcon
                 sx={{ 
                  marginLeft: 0.5, 
                  marginRight: 0.5,
                  color: "grey.900",        // Adjust the color to make the icon lighter
                  opacity: 0.7,             // Reduce opacity to soften the icon further
                }} 
                  />
                  {article.clicks}
                </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>

          {!isMobile && index < article.length - 1 && index % 3 !== 2 && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                position: "absolute",
                right: "10%",

                top: "0",
                bottom: "4%",
                borderColor: "rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export const SEO = () => {
  const [article, setArticle] = useState("");

  const { slug } = useParams();
  console.log(slug);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const sendDataToAddArticleForm = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/getonearticles/${slug}`
        );
        const article = response.data;
        console.log(article);

        setArticle(article);

        // call the cliks api with id
        await axios.post(
          `${process.env.REACT_APP_URL}/api/cards/clicked/${article._id}`
        );
      } catch (error) {
        console.error("Error sending data to addarticles:", error);
      }
    };

    // Call the function to send data
    sendDataToAddArticleForm();
  }, [slug]);
  return (
    <Grid
      xs={12}
      md={12}
      lg={12}
      container
      // marginLeft={2}
      // border={"2px solid black"}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />

      {/* <Grid container xs={12} md={9} lg={9} > */}
      <Grid
        item
        xs={12}
        md={10}
        lg={10}
        // border='2px solid black'
        mt={isMobile ? 10 : 15}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: 2,
          py: 4,
          textAlign: "center",
          "@media (max-width: 600px)": {
            px: 1,
            py: 2,
          },
        }}
      >
        <Typography variant="caption" color="error" sx={{ fontWeight: "bold" }}>
          MEMBER EXCLUSIVE
        </Typography>
        <Typography
          variant="h4"
          component="h1"
          sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
        >
          {article.headertext}
        </Typography>

        <Grid
          item
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 3 }}
          spacing={1}
          paddingBottom={2}
        >
          <Grid item>
            <Typography variant="subtitle2" component="p">
              By{" "}
              </Typography>
              <Link
                to={`/profile/${article.author}`}
                underline="hover"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  cursor:"pointer",
                  "&:hover": {
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                  },
                }}
              >
                {article.author}
              </Link>
              <Typography>
              {" "}
              &middot; July 25, 2024
            </Typography>
          </Grid>
          <Grid item>
            <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
              <FacebookIcon fontSize="small" />
            </IconButton>
            <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
              <LinkedInIcon fontSize="small" />
            </IconButton>
            <IconButton href="#" sx={{ color: "grey", p: 0.5 }}>
              <RedditIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={10} lg={10}></Grid>

        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          component="img"
          p={isMobile ? 3 : 0}
          src={article.imagelink}
          alt="Sample GIF"
          sx={{
            height: "auto",
            mb: 0,
            width: "auto",
          }}
        />
      </Grid>
      {/* </Grid> */}

      <Grid item xs={12} md={10} lg={10} p={isMobile ? 3 : 0}>
        <Divider
          sx={{
            orientation: "horizontal",
            borderColor: "#000000",
            flex: 1,
            // fontWeight: "bold",
            // marginLeft: "55px",
            mb: 2,
            justifyContent: "space-between",
            // mb: { xs: 0, md: 0 },
          }}
        />
      </Grid>

      <Grid
        container
        // spacing={7}
        // gap={1}
        // mt={-6}
        xs={12}
        md={10}
        lg={9}
        sx={{ backgroundColor: "" }}
      >
        <Grid item xs={12} md={12} lg={12} p={isMobile ? 3 : 0}>
          <Banner1 article={article} />

          <Grid
            item
            xs={12}
            md={11}
            lg={11}
            p={isMobile ? 3 : 0}
            paddingTop={3}
          >
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "#000000",
                flex: 1,
                // fontWeight: "bold",
                // marginLeft: "55px",
                // mb: 2
                justifyContent: "space-between",
                // mb: { xs: 0, md: 0 },
              }}
            />
          </Grid>

          <NewsletterSignUp />
        </Grid>

        {/* <Grid item xs={12} md={4} lg={4} padding={isMobile ? 3 : 0}>
          <BlogPosts />
        </Grid> */}
      </Grid>

      {/* <Grid item spacing={0} xs={12} md={10} lg={10} p={isMobile ? 3 : 0}>
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "left",
              display: "flex",
              fontWeight: 850,
              fontSize: 25,
              textTransform: "uppercase",
              paddingBottom: 3.5,
              paddingTop: 7.5,
            }}
          >
            MEDIA
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Divider
                sx={{
                  borderColor: "#d6dbd8",
                  flexGrow: 1,
                  marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
                  height: 3, // Horizontal divider's height
                }}
              />
              <Link
                href="#"
                sx={{
                  paddingLeft: "20px",
                  textAlign: "left",
                  fontSize: "12px", // Reduced text size
                  // fontStyle: "italic",
                  textDecoration: "none",
                  color: "#000",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                View more 
              </Link>
            </Grid>
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <ImageSection3 />
        </Grid>
      </Grid> */}

<Grid item spacing={0} xs={12} md={10} lg={10} p={isMobile ? 3 : 0}>
  <Grid item xs={12}>
    <Typography
      sx={{
        textAlign: "left",
        display: "flex",
        fontWeight: 850,
        fontSize: 25,
        textTransform: "uppercase",
        paddingBottom: 3.5,
        paddingTop: 7.5,
      }}
    >
      MEDIA
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Divider
          sx={{
            borderColor: "#d6dbd8",
            flexGrow: 1,
            marginLeft: { xs: "10px", md: "25px" }, // Adjust margin for responsiveness
            height: 3, // Horizontal divider's height
          }}
        />
        <Link
          href="#"
          sx={{
            paddingLeft: "20px",
            textAlign: "left",
            fontSize: "10px", // Reduced text size
            textDecoration: "none",
            color: "#000",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          View more
        </Link>
      </Grid>
    </Typography>
  </Grid>

  <Grid item xs={12} md={12} lg={12}>
    <ImageSection3 />
  </Grid>
</Grid>


      <Grid container xs={12} md={10} lg={10} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default SEO;



