// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   Select,
//   FormControl,
//   InputLabel,
//   CircularProgress,
// } from "@mui/material";
// import Texteditor from "./Texteditor";
// import { useNavigate } from "react-router-dom";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import axios from "axios";
// import Alert from "@mui/material/Alert";
// import { Editor } from "@tinymce/tinymce-react";
// import { handleEditorImageUpload } from "../../utils/imageUpload";
// import Snackbar from "@mui/material/Snackbar";
// import SnackbarContent from "@mui/material/SnackbarContent";

// export const AddArticleForm = () => {
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedAuthor, setSelectedAuthor] = useState("");
//   const [headerText, setHeaderText] = useState("");
//   const [metaDescription, setMetaDescription] = useState("");
//   const [shortDescription, setShortDescription] = useState("");
//   const [bodyText, setBodyText] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");
//   const [responseMessageError, setResponseMessageError] = useState("");
//   const navigate = useNavigate();
//   const [authors, setAuthors] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [successMessage, setSuccessMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const initialValues = {
//     badge: "",
//     title: "",
//     headertext: "",
//     imagelink: null,
//     body: "",
//     author: "",
//     date: "",
//   };
//   const [rawHtml, setRawHtml] = useState("");
//   const [input, setInputs] = useState(initialValues);

//   // const staticCategories = [
//   //   { value: "Technology", label: "Technology" },
//   //   { value: "Health", label: "Health" },
//   //   { value: "Finance", label: "Finance" },
//   //   { value: "Education", label: "Education" },
//   //   { value: "Lifestyle", label: "Lifestyle" },
//   // ];

//   // const staticAuthors = [
//   //   { value: "abc", label: "Author 1" },
//   //   { value: "xyz", label: "Author 2" },
//   // ];

//   const [file, setFile] = useState(null);

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;

//     if (name === "imagelink" && files.length > 0) {
//       const selectedFile = files[0];
//       const sanitizedFileName = selectedFile.name.replace(/\s+/g, "_");

//       if (!selectedFile.type.startsWith("image/")) {
//         alert("Please select an image file.");
//         return;
//       }

//       const sanitizedFile = new File([selectedFile], sanitizedFileName, {
//         type: selectedFile.type,
//       });
//       setInputs((prevState) => ({
//         ...prevState,
//         [name]: sanitizedFile,
//       }));
//     } else {
//       setInputs((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     const formData = new FormData();
//   //     Object.entries(input).forEach(([key, value]) => {
//   //       formData.append(key, value);
//   //     });
//   //     const response = await axios.post(
//   //       `${process.env.REACT_APP_URL}/api/cards`,
//   //       formData
//   //     );

//   //     if (response.data && response.data.message === "Article created") {
//   //       setInputs(initialValues);
//   //       setSnackbarMessage("Article created successfully!");
//   //       setSnackbarSeverity("success");
//   //       setSnackbarOpen(true);
//   //     }
//   //   } catch (err) {
//   //     setSnackbarMessage("Failed to create the article. Please try again.");
//   //     setSnackbarSeverity("error");
//   //     setSnackbarOpen(true);
//   //   }
//   // };

//   //  const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const formData = new FormData();
//   //   Object.entries(input).forEach(([key, value]) => {
//   //     formData.append(key, value);
//   //   });

//   //   try {
//   //     const response = await axios.post(
//   //       `${process.env.REACT_APP_URL}/api/cards`,
//   //       formData
//   //     );

//   //     if (response.data.message === "Article created") {
//   //       setInputs({
//   //         headertext: "",
//   //         metadescription: "",
//   //         shortdescription: "",
//   //         imagelink: null,
//   //         category: "",
//   //         author: "",
//   //       });
//   //       setSnackbarMessage("Article created successfully!");
//   //       setSnackbarSeverity("success");
//   //       setSnackbarOpen(true);
//   //       setSuccessMessage("Article created successfully!");

//   //     }
//   //   } catch (error) {
//   //     setSnackbarMessage("Failed to create the article. Please try again.");
//   //     setSnackbarSeverity("error");
//   //     setSnackbarOpen(true);
//   //   }
//   // };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const formData = new FormData();
//     Object.entries(input).forEach(([key, value]) => {
//       formData.append(key, value);
//     });

//     try {
//       await axios.post(
//         `${process.env.REACT_APP_URL}/api/cards`,
//         formData
//       );
//       setInputs({
//         headertext: "",
//         metadescription: "",
//         shortdescription: "",
//         imagelink: null,
//         category: "",
//         author: "",
//       });
//       setSnackbarMessage("Success");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);
//       // navigate("/articlestable");
//       navigate("/articlestable", { state: { message: "Article created successfully!" } }, { replace: true });

//     } catch (error) {
//       setSnackbarMessage("Failed to create the article. Please try again.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     }

//     finally {
//       setLoading(false); // Set loading to false when operation finishes
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       handleSubmit(e);
//     }
//   };

//   const rawHtmlHandler = (e) => {
//     setRawHtml(e.target.value);
//   };

//   useEffect(() => {
//     setLoading(true);
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/link`)
//       .then((response) => {
//         const formattedAuthors = response.data.authors
//           .reverse()
//           .map((author, index) => ({
//             id: author.id || index + 1,
//             authorName: author.authorName || "N/A",
//             linkedIn: author.linkedIn || "N/A",
//             twitter: author.twitter || "N/A",
//             description: author.description || "N/A",
//             file: author.file
//               ? `${author.file}`
//               : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&s", // Concatenate base URL with file path
//           }));
//         setAuthors(formattedAuthors);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the authors!", error);
//       })
//       .finally(() => {
//         setLoading(false); // Set loading to false when fetching finishes
//       });
//   }, []);

//   useEffect(() => {
//     setLoading(true);
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/getcategories`)
//       .then((response) => {
//         // setCategories(response.data);

//         console.log(response.data);

//         const formattedCategories = response.data
//           .reverse()
//           .map((categories, index) => {
//             return {
//               id: categories.id || index + 1, // Use index as fallback if id is undefined
//               categoriesName: categories.categoriesName || "N/A",
//             };
//           });

//         setCategories(formattedCategories);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the categories!", error);
//       })
//       .finally(() => {
//         setLoading(false); // Set loading to false when fetching finishes
//       });
//   }, []);

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import Texteditor from "./Texteditor";
import { useNavigate } from "react-router-dom";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { Editor } from "@tinymce/tinymce-react";
import { handleEditorImageUpload } from "../../utils/imageUpload";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

export const AddArticleForm = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [responseMessageError, setResponseMessageError] = useState("");
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [date, setDate] = useState('');

  const initialValues = {
    badge: "",
    title: "",
    headertext: "",
    imagelink: null,
    body: "",
    author: "",
    date: "",
  };
  const [rawHtml, setRawHtml] = useState("");
  const [input, setInputs] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "imagelink" && files.length > 0) {
      const selectedFile = files[0];
      const sanitizedFileName = selectedFile.name.replace(/\s+/g, "_");

      if (!selectedFile.type.startsWith("image/")) {
        alert("Please select an image file.");
        return;
      }

      const sanitizedFile = new File([selectedFile], sanitizedFileName, {
        type: selectedFile.type,
      });
      setInputs((prevState) => ({
        ...prevState,
        [name]: sanitizedFile,
      }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form submission starts
    const formData = new FormData();
    Object.entries(input).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/cards`,
        formData
      );
      setInputs({
        headertext: "",
        metadescription: "",
        shortdescription: "",
        imagelink: null,
        category: "",
        author: "",
      });
      console.log("====================================");
      console.log(response);
      console.log("====================================");
      if (response.status === 201) {
        setLoading(false);
        setSnackbarMessage("Success");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        navigate("/articlestable");
      } else if (response.status === 400) {
        setSnackbarMessage("Article already present");
        setSnackbarSeverity("error");
      } else if (response.status === 500) {
        setSnackbarMessage("Error in creating article");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Failed to create the article. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false); // Set loading to false when operation finishes
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const rawHtmlHandler = (e) => {
    setRawHtml(e.target.value);
  };

  useEffect(() => {
    const fetchAuthorsAndCategories = async () => {
      setLoading(true);
      try {
        // Fetch authors and categories in parallel
        const [authorsResponse, categoriesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_URL}/api/link`),
          axios.get(`${process.env.REACT_APP_URL}/api/getcategories`),
        ]);

        // Process authors data
        const formattedAuthors = authorsResponse.data.authors
          .reverse()
          .map((author, index) => ({
            id: author.id || index + 1,
            authorName: author.authorName || "N/A",
            linkedIn: author.linkedIn || "N/A",
            twitter: author.twitter || "N/A",
            description: author.description || "N/A",
            file: author.file
              ? `${author.file}`
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&s",
          }));
        setAuthors(formattedAuthors);

        // Process categories data
        const formattedCategories = categoriesResponse.data
          .reverse()
          .map((categories, index) => ({
            id: categories.id || index + 1,
            categoriesName: categories.categoriesName || "N/A",
          }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuthorsAndCategories();
  }, []);

  return (
    <Grid
      container
      direction="row"
      mt={12}
      p={6}
      justifyContent={"center"}
      onKeyDown={handleKeyDown}
    >
      <DrawerHeader />

      {successMessage && (
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{
            color: successMessage.includes("successfully") ? "green" : "red",
          }}
        >
          {successMessage}
        </Typography>
      )}

      {loading ? (
        <Grid container justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="primary" /> {/* Loader */}
        </Grid>
      ) : (
        <Grid
          Container
          maxWidth="md"
          sx={{
            marginTop: 4,
            backgroundColor: "#f0f8ff", // Light blue background
            padding: 4,
            borderRadius: 2,
            boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)", // Blue shadow
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: "#003366" }} // Dark blue text
          >
            Create a New Blog
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  color: "#003366", // Dark blue text
                  backgroundColor: "#add8e6", // Light blue background
                  padding: 1,
                  borderRadius: 1,
                  marginBottom: 3,
                  textAlign: "center",
                }}
              >
                Enter details to create a Blog
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  select
                  fullWidth
                  label="Category"
                  variant="outlined"
                  name="category"
                  value={input.category}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: "white",
                    ".MuiInputLabel-root": { color: "#003366" }, // Dark blue label
                    ".MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#003366" }, // Dark blue border
                      "&:hover fieldset": { borderColor: "#0000ff" }, // Blue on hover
                      "&.Mui-focused fieldset": { borderColor: "#1e90ff" }, // Dodger blue on focus
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        overflowY: "auto", // Set the maximum height for the dropdown menu
                      },
                    },
                  }}
                >
                  {categories.map((categories) => (
                    <MenuItem
                      key={categories.id}
                      value={categories.categoriesName}
                    >
                      {categories.categoriesName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Authors</InputLabel>
                <Select
                  select
                  fullWidth
                  label="Author"
                  variant="outlined"
                  name="author"
                  value={input.author}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: "white",
                    ".MuiInputLabel-root": { color: "#003366" }, // Dark blue label
                    ".MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#003366" }, // Dark blue border
                      "&:hover fieldset": { borderColor: "#0000ff" }, // Blue on hover
                      "&.Mui-focused fieldset": { borderColor: "#1e90ff" }, // Dodger blue on focus
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        overflowY: "auto", // Set the maximum height for the dropdown menu
                      },
                    },
                  }}
                >
                  {authors.map((author) => (
                    <MenuItem key={author.id} value={author.authorName}>
                      {author.authorName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Header"
                variant="outlined"
                name="headertext"
                value={input.headertext}
                onChange={handleChange}
                sx={{
                  backgroundColor: "white",
                  ".MuiInputLabel-root": { color: "#003366" }, // Dark blue label
                  ".MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#003366" }, // Dark blue border
                    "&:hover fieldset": { borderColor: "#0000ff" }, // Blue on hover
                    "&.Mui-focused fieldset": { borderColor: "#1e90ff" }, // Dodger blue on focus
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Meta Description"
                variant="outlined"
                name="metadescription"
                value={input.metadescription}
                onChange={handleChange}
                sx={{
                  backgroundColor: "white",
                  ".MuiInputLabel-root": { color: "#003366" }, // Dark blue label
                  ".MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#003366" }, // Dark blue border
                    "&:hover fieldset": { borderColor: "#0000ff" }, // Blue on hover
                    "&.Mui-focused fieldset": { borderColor: "#1e90ff" }, // Dodger blue on focus
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Short Description"
                variant="outlined"
                name="shortdescription"
                value={input.shortdescription}
                onChange={handleChange}
                sx={{
                  backgroundColor: "white",
                  ".MuiInputLabel-root": { color: "#003366" }, // Dark blue label
                  ".MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#003366" }, // Dark blue border
                    "&:hover fieldset": { borderColor: "#0000ff" }, // Blue on hover
                    "&.Mui-focused fieldset": { borderColor: "#1e90ff" }, // Dodger blue on focus
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="imagelink"
                placeholder="Upload an image"
                accept="image/*"
                type="file"
                variant="outlined"
                sx={{
                  marginRight: "10px",
                  width: "48%",
                }}
                onChange={handleChange}
              />
              {input.imagelink && (
                <img
                  src={URL.createObjectURL(input.imagelink)}
                  alt="Preview"
                  style={{ height: 100 }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="rawHtml"
                value={rawHtml}
                placeholder="Enter body for article..."
                type="text"
                variant="outlined"
                multiline
                rows={10}
                sx={{
                  marginBottom: "20px",
                  width: "100%",
                }}
                onChange={rawHtmlHandler}
              />
            </Grid>

            {/* <Grid item xs={12}>
         
            <Editor
              apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
              initialValue={rawHtml || ""}
              init={{
                height: 500,
                selector: "textarea",
                plugins:
                  "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion",
                toolbar:
                  "undo redo | styles |formatselect | bold italic  | forecolor backcolor emoticons | link | image |alignleft aligncenter alignright alignjustify |numlist bullist",

                menu: {
                  favs: {
                    title: "My Favorites",
                    items: "code visualaid | searchreplace | emoticons",
                  },
                },
                menubar:
                  "favs file edit view insert  lists format tools table help numlist bullist",

                forced_root_block: false,
                images_upload_handler: handleEditorImageUpload, // Use the handler for images upload
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; line-height:2 }",
              }}
              onEditorChange={(content) =>
                setInputs({ ...input, body: content })
              }
            />
          </Grid>  */}

            <Grid item xs={12}>
              <Editor
                 apiKey='7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe'
                initialValue={rawHtml || ""}
                init={{
                  height: 500,
                  selector: "textarea",
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion",
                  toolbar:
                    "undo redo | styles | formatselect | bold italic | forecolor backcolor emoticons | link | image | alignleft aligncenter alignright alignjustify | numlist bullist",
                  menu: {
                    favs: {
                      title: "My Favorites",
                      items: "code visualaid | searchreplace | emoticons",
                    },
                  },
                  menubar:
                    "favs file edit view insert lists format tools table help numlist bullist",
                  forced_root_block: false,
                  images_upload_handler: handleEditorImageUpload, // Use the handler for images upload
                  content_style: `
        body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; line-height: 2; }
        .mce-content-body img { width: 800px !important; height: 452px !important; object-fit: contain; }
      `,
                }}
                onEditorChange={(content) =>
                  setInputs({ ...input, body: content })
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ marginTop: 3 }}
          >
            <Grid item>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  backgroundColor: "#003366", // Dark blue button
                  "&:hover": { backgroundColor: "#0000ff" }, // Blue on hover
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => navigate("/articlestable")}
                variant="contained"
                sx={{
                  backgroundColor: "#ff6347", // Tomato color for cancel button
                  "&:hover": { backgroundColor: "#ff4500" }, // Orange red on hover
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position of Snackbar

            action={
              <Button color="inherit" onClick={() => setSnackbarOpen(false)}>
                Close
              </Button>
            }
          >
            <SnackbarContent
              message={snackbarMessage}
              style={{
                backgroundColor:
                  snackbarSeverity === "success" ? "#4caf50" : "#f44336", // Green for success, red for error
                color: "white",
              }}
            />
          </Snackbar>

          {responseMessage && (
            <Alert severity="success" color="success">
              <Typography variant="body1" color="secondary" mt={2}>
                {responseMessage}
              </Typography>
            </Alert>
          )}
          {responseMessageError && (
            <Typography
              variant="body1"
              color="error.main"
              align="center"
              sx={{ marginTop: 2 }}
            >
              {responseMessageError}
            </Typography>
          )}

          {successMessage && (
            <Typography
              variant="body1"
              align="center"
              gutterBottom
              sx={{
                color: successMessage.includes("successfully")
                  ? "green"
                  : "red",
              }}
            >
              {successMessage}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default AddArticleForm;
