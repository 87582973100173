// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Import useHistory
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Grid from "@mui/material/Grid";
// import marketingLogo from "../main/img/marketing_logo.webp";
// import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
// import Button from "@mui/material/Button";
// import Divider from "@mui/material/Divider";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import Tooltip from "@mui/material/Tooltip";
// import LoginIcon from "@mui/icons-material/Login";
// // import { useNavigate } from "react-router-dom";

// import LoginForm  from "../admin/adminComponents/LoginForm";

// function NavBar() {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
//   const [scrolled, setScrolled] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrolled(window.scrollY > 50);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleDrawer = (open) => (event) => {
//     console.log("====================================");
//     console.log("in toggle");
//     console.log("====================================");

//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   // const handleClick = () => {
//   //   navigate("/med"); // Navigate to /marketing-inc
//   // };

//   const handleClick = (path) => () => {
//     navigate(path);
//   };

//   const drawerList = (
//     <List
//       sx={{
//         width: 190,
//         height: "auto",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//       }}
//       role="presentation"
//     >
//       {[
//         { text: "Marketing News", path: "/marketing" },
//         { text: "Blog", path: "/mediablog" },
//         // { text: "Job", path: "/job" },
//         { text: "Client Profile", path: "/client-profile" },
//         { text: "About", path: "/about" },
//         { text: "Contact Us", path: "/contact" },
//       ].map(({ text, path }) => (
//         <ListItem button key={text} onClick={handleClick(path)}>
//           <ListItemText primary={text} />
//         </ListItem>
//       ))}
//       {isMobile && (
//         <>
//           <Divider />
//           <ListItem button component="a" href="mailto:your-email@example.com">
//             <Tooltip title="Email" placement="left-start">
//               <MailOutlineOutlinedIcon />
//             </Tooltip>
//           </ListItem>
//           <ListItem button>
//             <ListItemText primary="Subscribe" />
//           </ListItem>
//           <ListItem button>
//             <LoginIcon />
//           </ListItem>
//         </>
//       )}
//     </List>
//   );

//   const handleSubscribeHover = (event) => {
//     event.target.style.textDecoration = "underline";
//   };

//   const handleSubscribeHoverOut = (event) => {
//     event.target.style.textDecoration = "none";
//   };

//   const handleLogoClick = () => {
//     navigate("/"); // Navigate to /marketing-inc
//   };

//   const handleContactClick = () => {
//     navigate(""); // Navigate to /marketing-inc
//   };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: "white",
//         boxShadow: 2,
//         transition: "all 0.3s",
//         height: isMobile ? "70px" : scrolled ? "70px" : "120px",
//       }}
//     >
//       <Toolbar
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           transition: "all 0.3s",
//         }}
//       >
//         <Grid
//           container
//           xs={12}
//           md={10}
//           lg={10}
//           // border="2px solid black"
//           alignItems="center"
//         >
//           <Grid
//             item
//             xs={2}
//             md={0.5}
//             lg={0.5}
//             // border='2px solid black'
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-start",
//               // height:'50px',
//               // mb:5
//             }}
//           >
//             <IconButton
//               // edge="flex"
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//               sx={{
//                 display: { xs: "block", md: "block", lg: "flex" },
//                 position: "absolute",
//                 // alignItems: "center",
//                 // border: "2px solid black",
//               }}
//             >
//               <MenuIcon />
//             </IconButton>
//           </Grid>
//           <Grid
//             item
//             xs={10}
//             md={9}
//             lg={9}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: scrolled ? "flex-start" : "center",
//               width: "100%",
//               // position: isMobile ? "fixed" : "relative",
//               flexDirection: { xs: "column", md: "row" },
//               // border: "2px solid black",
//             }}
//           >
//             <Grid
//               item
//               component="img"
//               src={marketingLogo}
//               alt="Marketing Inc Logo"
//               sx={{
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: isMobile ? "45%" : scrolled ? "18%" : "25%",
//                 // Adjust logo width based on scroll
//                 height: "auto",
//                 mb: { md: 0 },
//                 ml: { xs: 0, md: scrolled ? 0 : 9 },
//                 mr: isMobile ? 6 : 0,
//                 padding: 0,
//                 transition: "width 0.3s",
//                 cursor: "pointer", // Smooth transition
//               }}
//               onClick={handleLogoClick} // Add onClick handler
//             />
//           </Grid>

//           {!isMobile && (
//             <Grid item xs={12} md={2.5} lg={2.5}>
//               <Grid
//                 alignItems="center"
//                 spacing={1}
//                 sx={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "end",
//                   padding: "6px",
//                 }}
//               >
//                 <Grid
//                   item
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-end",
//                     alignItems: "center",
//                   }}
//                 >
//                   <IconButton
//                     component="a"
//                     href="mailto:your-email@example.com"
//                     sx={{
//                       padding: 2,
//                       color: "black",
//                       "&:hover": { backgroundColor: "transparent" },
//                     }}
//                   >
//                     <MailOutlineOutlinedIcon />
//                   </IconButton>
//                 </Grid>
//                 <Divider
//                   orientation="vertical"
//                   flexItem
//                   sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
//                 />
//                 <Button
//                   sx={{
//                     color: "red",
//                     fontSize: ".75rem",
//                     textDecoration: "none",
//                     "&:hover": { textDecoration: "underline" },
//                   }}
//                   onMouseOver={handleSubscribeHover}
//                   onMouseOut={handleSubscribeHoverOut}
//                   onClick={handleContactClick}
//                 >
//                   Subscribe
//                 </Button>

//                 <Divider
//                   orientation="vertical"
//                   flexItem
//                   sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
//                 />

//               </Grid>
//             </Grid>
//           )}
//         </Grid>
//       </Toolbar>

//       {!isMobile && (
//         <Toolbar
//           sx={{
//             justifyContent: "center",
//             flexWrap: "wrap",
//             mr: 5,
//             transition: "all 0.3s",
//             display: scrolled ? "none" : "flex",
//           }}
//         >
//           <Grid container spacing={3} justifyContent="center">
//             {[
//               { text: "Marketing News", path: "/marketing" },
//               { text: "Blog", path: "/mediablog" },
//               // { text: "Job", path: "/job" },
//               // { text: "Client Profile", path: "/client-profile" },
//               { text: "About", path: "/about" },
//               { text: "Contact Us", path: "/contact" },
//             ].map(({ text, path }) => (
//               <Grid item key={text} onClick={handleClick(path)}>
//                 <Button
//                   sx={{
//                     color: "#000000",
//                     fontFamily: "Proxima Nova, sans-serif",
//                   }}
//                   onClick={handleClick}
//                 >
//                   {text}
//                 </Button>
//               </Grid>
//             ))}
//           </Grid>
//         </Toolbar>
//       )}
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </AppBar>
//   );
// }

// export default NavBar;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Grid from "@mui/material/Grid";
// import marketingLogo from "../main/img/marketing_logo.webp";
// import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
// import Button from "@mui/material/Button";
// import Divider from "@mui/material/Divider";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import Tooltip from "@mui/material/Tooltip";
// import {
//   TextField,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";

// import axios from "axios";

// function NavBar() {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [scrolled, setScrolled] = useState(false);
//   const [open, setOpen] = useState(false); // State for controlling dialog open/close
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("md"));
//   const navigate = useNavigate();
//   const [Name, setName] = useState("");
//   const [Email, setEmail] = useState("");
//   const [message, setMessage] = useState("");
//   const [responseMessage, setResponseMessage] = useState("");

//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//   });

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   const handleSubmit = async () => {
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/addsubscriber`,
//         {
//           formData: {
//             ...formData,
//           },
//         }
//       );

//       if (response.status === 201) {
//         console.log("successful");

//         // setResponseMessage(data.message);
//         handleClose();
//         window.location.reload(); // Reload to fetch the updated authors list
//       } else {
//         // setResponseMessage(data.message || "Error creating author");
//         console.log("====================================");
//         // console.log("error creating subscriber", data);
//         console.log("====================================");
//       }
//     } catch (error) {
//       setResponseMessage("Error: Unable to create author");
//     }
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event.type === "keydown" &&
//       (event.key === "Tab" || event.key === "Shift")
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const handleClick = (path) => () => {
//     navigate(path);
//   };

//   // Function to open the dialog
//   const handleOpen = () => {
//     setOpen(true);
//   };

//   // Function to close the dialog
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const drawerList = (
//     <List
//       sx={{
//         width: 190,
//         height: "auto",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//       }}
//       role="presentation"
//     >
//       {[
//         { text: "Marketing News", path: "/marketing" },
//         { text: "Blog", path: "/mediablog" },
//         { text: "Client Profile", path: "/client-profile" },
//         { text: "About", path: "/about" },
//         { text: "Contact Us", path: "/contact" },
//       ].map(({ text, path }) => (
//         <ListItem button key={text} onClick={handleClick(path)}>
//           <ListItemText primary={text} />
//         </ListItem>
//       ))}
//       {isMobile && (
//         <>
//           <Divider />
//           <ListItem button component="a" href="mailto:your-email@example.com">
//             <Tooltip title="Email" placement="left-start">
//               <MailOutlineOutlinedIcon />
//             </Tooltip>
//           </ListItem>
//           {/* Subscribe dialog within mobile drawer */}
//           {/* <ListItem button onClick={handleOpen}>
//             <LoginIcon />
//             <ListItemText primary="Subscribe" />
//           </ListItem> */}
//         </>
//       )}
//     </List>
//   );

//   const handleLogoClick = () => {
//     navigate("/");
//   };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: "white",
//         boxShadow: 2,
//         transition: "all 0.3s",
//         height: isMobile ? "70px" : scrolled ? "70px" : "120px",
//       }}
//     >
//       <Toolbar
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           transition: "all 0.3s",
//         }}
//       >
//         <Grid container xs={12} md={10} lg={10} alignItems="center">
//           <Grid
//             item
//             xs={2}
//             md={0.5}
//             lg={0.5}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-start",
//             }}
//           >
//             <IconButton
//               aria-label="menu"
//               onClick={toggleDrawer(true)}
//               sx={{
//                 display: { xs: "block", md: "block", lg: "flex" },
//                 position: "absolute",
//               }}
//             >
//               <MenuIcon />
//             </IconButton>
//           </Grid>
//           <Grid
//             item
//             xs={10}
//             md={9}
//             lg={9}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: scrolled ? "flex-start" : "center",
//               width: "100%",
//               flexDirection: { xs: "column", md: "row" },
//             }}
//           >
//             <Grid
//               item
//               component="img"
//               src={marketingLogo}
//               alt="Marketing Inc Logo"
//               sx={{
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: isMobile ? "45%" : scrolled ? "18%" : "25%",
//                 height: "auto",
//                 mb: { md: 0 },
//                 ml: { xs: 0, md: scrolled ? 0 : 9 },
//                 mr: isMobile ? 6 : 0,
//                 padding: 0,
//                 transition: "width 0.3s",
//                 cursor: "pointer",
//               }}
//               onClick={handleLogoClick}
//             />
//           </Grid>

//           {!isMobile && (
//             <Grid item xs={12} md={2.5} lg={2.5}>
//               <Grid
//                 alignItems="center"
//                 spacing={1}
//                 sx={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "end",
//                   padding: "6px",
//                 }}
//               >
//                 <Grid
//                   item
//                   sx={{
//                     display: "flex",
//                     justifyContent: "flex-end",
//                     alignItems: "center",
//                   }}
//                 >
//                   <IconButton
//                     component="a"
//                     href="mailto:your-email@example.com"
//                     sx={{
//                       padding: 2,
//                       color: "black",
//                       "&:hover": { backgroundColor: "transparent" },
//                     }}
//                   >
//                     <MailOutlineOutlinedIcon />
//                   </IconButton>
//                 </Grid>
//                 <Divider
//                   orientation="vertical"
//                   flexItem
//                   sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
//                 />
//                 <Button
//                   sx={{
//                     color: "red",
//                     fontSize: ".75rem",
//                     textDecoration: "none",
//                     "&:hover": { textDecoration: "underline" },
//                   }}
//                   onClick={handleOpen} // Open dialog on click
//                 >
//                   Subscribe
//                 </Button>
//                 <Divider
//                   orientation="vertical"
//                   flexItem
//                   sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
//                 />
//               </Grid>
//             </Grid>
//           )}
//         </Grid>
//       </Toolbar>

//       {!isMobile && (
//         <Toolbar
//           sx={{
//             justifyContent: "center",
//             flexWrap: "wrap",
//             mr: 5,
//             transition: "all 0.3s",
//             display: scrolled ? "none" : "flex",
//           }}
//         >
//           <Grid container spacing={3} justifyContent="center">
//             {[
//               { text: "Marketing News", path: "/marketing" },
//               { text: "Blog", path: "/mediablog" },
//               { text: "About", path: "/about" },
//               { text: "Contact Us", path: "/contact" },
//             ].map(({ text, path }) => (
//               <Grid item key={text} onClick={handleClick(path)}>
//                 <Button
//                   sx={{
//                     color: "#000000",
//                     fontFamily: "Proxima Nova, sans-serif",
//                   }}
//                 >
//                   {text}
//                 </Button>
//               </Grid>
//             ))}
//           </Grid>
//         </Toolbar>
//       )}
//       <Drawer
//         anchor="left"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//         sx={{ height: "auto", justifyContent: "center", alignItems: "center" }}
//       >
//         {drawerList}
//       </Drawer>

//       {/* Dialog for Subscribe */}
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Subscribe</DialogTitle>
//         <DialogContent>
//           {/* Name Field */}
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Name"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             type="text"
//             fullWidth
//             variant="standard"
//           />
//           {/* Email Field */}
//           <TextField
//             margin="dense"
//             label="Email"
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             fullWidth
//             variant="standard"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={handleSubmit}>Subscribe</Button>
//         </DialogActions>
//       </Dialog>
//     </AppBar>
//   );
// }

// export default NavBar;


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import marketingLogo from "../main/img/marketing_logo.webp";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from "@mui/material";
import axios from "axios";

function NavBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false); // State for controlling dialog open/close
  const [responseMessage, setResponseMessage] = useState(""); // State for response message
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // You can adjust this value as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/addsubscriber`,
        {
          formData: {
            ...formData,
          },
        }
      );

      if (response.status === 201) {
        setResponseMessage("Subscription successful!");
        setOpenSnackbar(true); // Show Snackbar on success
        handleClose();
        window.location.reload(); // Reload to fetch the updated authors list
      } else {
        console.log("Error creating subscriber");
      }
    } catch (error) {
      setResponseMessage("Error: Unable to create author");
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (path) => () => {
    navigate(path);
  };

  // Function to open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  const drawerList = (
    <List
      sx={{
        width: 190,
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      role="presentation"
    >
      {[
        { text: "Marketing News", path: "/marketing" },
        { text: "Blog", path: "/mediablog" },
        { text: "Client Profile", path: "/client-profile" },
        { text: "About", path: "/about" },
        { text: "Contact Us", path: "/contact" },
      ].map(({ text, path }) => (
        <ListItem button key={text} onClick={handleClick(path)}>
          <ListItemText primary={text} />
        </ListItem>
      ))}
      {isMobile && (
        <>
          <Divider />
          <ListItem button component="a" href="mailto:your-email@example.com">
            <Tooltip title="Email" placement="left-start">
              <MailOutlineOutlinedIcon />
            </Tooltip>
          </ListItem>
        </>
      )}
    </List>
  );

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        boxShadow: 2,
        transition: "all 0.3s",
        height: isMobile ? "70px" : scrolled ? "70px" : "120px",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s",
        }}
      >


<Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={responseMessage}
        sx={{ '& .MuiSnackbarContent-root': { backgroundColor: 'green' } }}
      />

        <Grid container xs={12} md={10} lg={10} alignItems="center">
          <Grid
            item
            xs={2}
            md={0.5}
            lg={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                display: { xs: "block", md: "block", lg: "flex" },
                position: "absolute",
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={10}
            md={9}
            lg={9}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: scrolled ? "flex-start" : "center",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid
              item
              component="img"
              src={marketingLogo}
              alt="Marketing Inc Logo"
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: isMobile ? "45%" : scrolled ? "18%" : "25%",
                height: "auto",
                mb: { md: 0 },
                ml: { xs: 0, md: scrolled ? 0 : 9 },
                mr: isMobile ? 6 : 0,
                padding: 0,
                transition: "width 0.3s",
                cursor: "pointer",
              }}
              onClick={handleLogoClick}
            />
          </Grid>

          {!isMobile && (
            <Grid item xs={12} md={2.5} lg={2.5}>
              <Grid
                alignItems="center"
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  padding: "6px",
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    component="a"
                    href="mailto:your-email@example.com"
                    sx={{
                      padding: 2,
                      color: "black",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <MailOutlineOutlinedIcon />
                  </IconButton>
                </Grid>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
                />
                <Button
                  sx={{
                    color: "red",
                    fontSize: ".75rem",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={handleOpen} // Open dialog on click
                >
                  Subscribe
                </Button>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: 18, marginLeft: 1, marginRight: 1, mt: 2 }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>

      {!isMobile && (
        <Toolbar
          sx={{
            justifyContent: "center",
            flexWrap: "wrap",
            mr: 5,
            transition: "all 0.3s",
            display: scrolled ? "none" : "flex",
          }}
        >
          <Grid container spacing={3} justifyContent="center">
            {[
              { text: "Marketing News", path: "/marketing" },
              { text: "Blog", path: "/mediablog" },
              { text: "About", path: "/about" },
              { text: "Contact Us", path: "/contact" },
            ].map(({ text, path }) => (
              <Grid item key={text} onClick={handleClick(path)}>
                <Button
                  sx={{
                    color: "#000000",
                    fontFamily: "Proxima Nova, sans-serif",
                  }}
                >
                  {text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      )}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ height: "auto", justifyContent: "center" }}
      >
        {drawerList}
      </Drawer>

      {/* Dialog for Subscription */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Subscribe</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={responseMessage}
        sx={{ '& .MuiSnackbarContent-root': { backgroundColor: 'green' } }}
      />
    </AppBar>
  );
}

export default NavBar;
