import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiAppBar from "@mui/material/AppBar";
import { useState, useEffect } from "react";
import HeaderTopbar from "./HeaderTopbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useLocation } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import LogoutIcon from "@mui/icons-material/Logout";
import marketingLogo from "../../main/img/marketing_logo.webp";
// import { useTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import ArticleIcon from "@mui/icons-material/Article";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

export const DrawerHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerWidth = 240;
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true); // Open sidebar
  };

  const handleDrawerClose = () => {
    setOpen(false); // Close sidebar
  };

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 10px)`, // Increase width here
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 10px)`, // Adjust for breakpoints
    },
  });

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white", // Set background color to white
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const LogoContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  });

  // const getPaperStyle = (path) => ({
  //     margin: 10,
  //     backgroundColor: location.pathname === path ? '#e0e0e0' : 'white'
  // });

  const getPaperStyle = (paths) => ({
    margin: 10,
    backgroundColor: paths.includes(location.pathname) ? "#e0e0e0" : "white",
  });

  return (
    <Grid>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Grid container xs={12} sm={12} md={12}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"start"}
            >
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  ...(open && { display: "none" }),

                  justifyContent: "flex-start",
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} sm={6} md={5}>
              <Box display="flex" justifyContent="flex-end">
                <HeaderTopbar />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid component={Drawer} variant="permanent" open={open}>
        <DrawerHeader>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "left",
              px: 2.5,
              bgcolor: "white",
              justifyItems: "center",
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                border: "2px solid white",
              }}
            ></ListItemIcon>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <LogoContainer>
                <img
                  src={marketingLogo}
                  alt="Logo"
                  style={{ width: isMobile ? "80px" : "150px" }}
                />
              </LogoContainer>

              <IconButton
                onClick={handleDrawerClose}
                sx={{ alignItems: "end" }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </Box>
          </ListItemButton>
        </DrawerHeader>

        <Divider />
        <List>
          <Link to={"/dashboard"}>
            <Tooltip title="Home" placement="right-start">
              <Paper
                elevation={3}
                style={getPaperStyle("/dashboard")}
                sx={{ flexDirection: "row" }}
              >
                <ListItemIcon
                  sx={{
                    padding: 1.5,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HomeWorkIcon sx={{ color: "#black" }} />
                  {open ? (
                    <ListItemText
                      primary="Dashboard"
                      sx={{ opacity: open ? 1 : 0, ml: 1 }}
                    />
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              </Paper>
            </Tooltip>
          </Link>

          <Link to={"/addauthor"}>
            <Tooltip title="Add Author" placement="right-start">
              <Paper
                elevation={3}
                style={getPaperStyle("/addauthor")}
                sx={{ flexDirection: "row" }}
              >
                <ListItemIcon
                  sx={{
                    padding: 1.5,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PersonIcon sx={{ color: "#black" }} />

                  {open ? (
                    <ListItemText
                      primary="Add Author"
                      sx={{ opacity: open ? 1 : 0, ml: 1 }}
                    />
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              </Paper>
            </Tooltip>
          </Link>

          <Link to={"/addcategories"}>
            <Tooltip title="Add Categories" placement="right-start">
              <Paper
                elevation={3}
                style={getPaperStyle("/addcategories")}
                sx={{ flexDirection: "row" }}
              >
                <ListItemIcon
                  sx={{
                    padding: 1.5,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CategoryIcon sx={{ color: "#black" }} />

                  {open ? (
                    <ListItemText
                      primary="Add Categories"
                      sx={{ opacity: open ? 1 : 0, ml: 1 }}
                    />
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              </Paper>
            </Tooltip>
          </Link>

          <Link to={"/articlestable"}>
            <Tooltip title="Blogs" placement="right-start">
              <Paper
                elevation={3}
                style={getPaperStyle("/articlestable")}
                sx={{ flexDirection: "row" }}
              >
                <ListItemIcon
                  sx={{
                    padding: 1.5,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon sx={{ color: "#black" }} />

                  {open ? (
                    <ListItemText
                      primary="Add Article"
                      sx={{ opacity: open ? 1 : 0, ml: 1 }}
                    />
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              </Paper>
            </Tooltip>
          </Link>

          <Link to={"/subscribe"}>
            <Tooltip title="subscriber" placement="right-start">
              <Paper
                elevation={3}
                style={getPaperStyle("/subscribe")}
                sx={{ flexDirection: "row" }}
              >
                <ListItemIcon
                  sx={{
                    padding: 1.5,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SubscriptionsIcon sx={{ color: "#black" }} />

                  {open ? (
                    <ListItemText
                      primary="Add Subscriber"
                      sx={{ opacity: open ? 1 : 0, ml: 1 }}
                    />
                  ) : (
                    <></>
                  )}
                </ListItemIcon>
              </Paper>
            </Tooltip>
          </Link>

        </List>
        <Divider />

        {/* </Drawer> */}
      </Grid>
    </Grid>
  );
};

export default DrawerHeader;
